import {
  TextField,
  IndexTable,
  LegacyCard,
  Collapsible,
  IndexFilters,
  useSetIndexFiltersMode,
  useIndexResourceState,
  Text,
  ChoiceList,
  RangeSlider,
  Badge,
  Avatar,
  Button,
  Icon,
  Select,
  Checkbox,
  Page,
  Tag,
  Tooltip,
} from "@shopify/polaris";
import { LegacyStack } from "@shopify/polaris";
import { DeleteMajor, CancelMajor } from "@shopify/polaris-icons";
import { useState, useCallback, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import AddCategoryAgent from "../UI/AddCategoryAgent";
import { ChevronDownMinor, NoteMinor } from "@shopify/polaris-icons";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  BgThemeButtonDisabled,
  BookingStatusButton,
  CPButton,
  CheckWithoutBgUnderlineBtn,
  ViewVoucherButton,
  WithoutBgButtonBlue,
} from "../UI/Buttons";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import { customers_inner_list } from "../../assets/constant";
import SidebarInnerLists from "../CustomComponents/SidebarInnerLists";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../UI/Loader/Loader";
import NoDataFound from "../CustomComponents/NoDataFound";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import AddCompanyCredits from "../UI/AddCompanyCredits";
import AddAdvancePayments from "../UI/AddAdvancePayments";

import "./NewAgents.css";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import VerticalInputSelectCustomCopy from "../CustomComponents/VerticalInputSelectCustomCopy";
import CustomToggleButton from "../UI/CustomToggleButton";
import TruncatedText from "../CustomComponents/TruncatedText/TruncatedText";
import { formatIndianCurrency } from "../../utils/utils";
import BookingStatusUpdate from "../CustomComponents/BookingStatusUpdate";
import CustomPaginationArrows from "../CustomComponents/CustomPagination/CustomPaginationArrows";
import jsPDF from "jspdf";
// import html2canvas from "html2canvas";
import UpliftMenuButton from "../NewCustomComp/UpliftMenuButton/UpliftMenuButton";
import { getDecryptedData } from "../../utils/encryptStorage";
import {
  CustomShopifyIndexTable,
  CustomShopifyIndexTableCell,
  CustomShopifyIndexTableRow,
} from "../CustomComponents/CustomShopifyIndexTable/CustomShopifyIndexTable";
import ConfirmPopup from "../NewCustomComp/confirmPopup/ConfirmPopup";
import Pagination from "../NewCustomComp/pagination/Pagination";
import ApiSearchInput from "../NewCustomComp/apiSearchInput/ApiSearchInput";
import EditAgentPopup from "./EditAgentPopup";

const NewAgents = () => {
  const [showPopUp, setShowPopUp] = useState(false);
  const [showCancelAgent, setShowCancelAgent] = useState(false);
  const [activeAgentId, setActiveAgentId] = useState(false);

  const [showModelMenuActive, setShowModelMenuActive] = useState(false);
  const [companyCredits, setCompanyCredits] = useState(false);
  const [advancePayment, setAdvancePayment] = useState(false);

  const [showAddModelMenuActive, setShowAddModelMenuActive] = useState(false);

  const [showEditModelMenuActive, setShowEditModelMenuActive] = useState(false);

  const permission = JSON.parse(localStorage.getItem("permissions"));

  const [permissions, setPermissions] = useState(permission);
  const [open8, setOpen8] = useState(true);
  const handleToggle8 = useCallback(() => setOpen8((open8) => !open8), []);
  const [buttonLoader, setButtonLoader] = useState(false);

  useEffect(() => {
    setPermissions(permissions);
  }, [permissions]);

  const userToken = getDecryptedData("encryptToken");
  const property_id = useSelector((state) => state.property.value);

  const [orders, setOrders] = useState([]);
  const [agents, setAgents] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // fetch data
  const [allProperties, setAllProperties] = useState([]);

  const [selectedTags, setSelectedTags] = useState([]);

  const removeTag = useCallback(
    (tag) => () => {
      setSelectedTags((previousTags) =>
        previousTags.filter((previousTag) => previousTag.id !== tag)
      );
    },
    [selectedTags]
  );

  const removePropertyHandler = (tag) => {
    setSelectedTags((previousTags) =>
      previousTags.filter((previousTag) => previousTag?.id !== tag)
    );
  };

  const addAssignHotel = (e) => {
    const foundProperty = allProperties.find(
      (property) => property.property_name === e.target.value
    );
    if (foundProperty) {
      const isPropertyExists = selectedTags.some(
        (property) => property.id === foundProperty.id
      );

      if (!isPropertyExists) {
        const newProperty = {
          id: foundProperty.id,
          name: foundProperty.property_name,
        };

        setSelectedTags((prevState) => [...prevState, newProperty]);
      } else {
        showErrorToast("Already Exists");
      }
    }
  };

  const tagMarkup = selectedTags.map((option) => (
    <Tag key={option} onRemove={removeTag(option)}>
      {option}
    </Tag>
  ));

  const [addAgentData, setAddAgentData] = useState({
    agent_name: "",
    agent_email: "",
    agent_phone: "",
    agent_category: "",
    company_name: "",
    business_type: "",
    agent_pan: "",
    agent_gst: "",
    settled_from: "",
    credit_amount: "",
    balance: "",
    agent_description: "",
    assigned_properties: "",

    max_hold_time: "",
    advance_amount: "",
    total_credit_amount: "",
    total_advance_amount: "",
    agent_information: "",
    balance_amount: "",
    temporary_booking: "",
    used_amount: "",
    // settle_from: "",

    // assigned_properties:"]", "2"
  });

  // Onchange Function
  const handleChangeAgentData = (event) => {
    setAddAgentData({
      ...addAgentData,
      [event.target.name]: event.target.value,
    });
  };

  const [temporaryBooking, setTemporaryBooking] = useState("");
  const [showMaxHold, setShowMaxHold] = useState(false);

  const temporaryBookingsHandle = useCallback(
    (data) => {
      setTemporaryBooking(data === "enabled" ? 1 : 0);
      if (temporaryBooking === 1) {
        setShowMaxHold(true);
      } else {
        setShowMaxHold(false);
      }
    },
    [showMaxHold, temporaryBooking, addAgentData.max_hold_time]
  );

  const [btnStatus, setButtonStatus] = useState([]);

  const [loader, setLoader] = useState(true);
  // ..............Pagination..........
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handlePageChange = (page) => {
    if (page) {
      fetchInfo(property_id?.id, page);
    }
  };
  const fetchInfo = async (id, page) => {
    const url = page
      ? `${process.env.REACT_APP_BASE_URL}/api/v1/agents/${id}?page=${page}&pagination=true`
      : `${process.env.REACT_APP_BASE_URL}/api/v1/agents/${id}?pagination=true`;
    try {
      setLoader(true);
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      const data = await response.json();
      if (data.success) {
        setAgents(data);
        setLoader(false);
      } else {
        showErrorToast(data.message);
        setAgents("");
      }
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };
  useEffect(() => {
    if (property_id) {
      fetchInfo(property_id?.id);
      fetchAgentCategories(property_id?.id);
    }
  }, [property_id]);

  const handleUpdateAgents = (event) => {
    event.preventDefault();
    setButtonLoader(true);
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/agents/update/${addAgentData.agent_information}/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          ...addAgentData,
          account_id: property_id?.account_id,
          assigned_properties: selectedTags,
          credit_amount: addAgentData.total_credit_amount,
          temporary_booking: temporaryBooking,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setButtonLoader(false);
          navigate("/agents");
          fetchInfo(property_id?.id);
          showSuccessToast("Agent Updated Successfully");
          setShowEditModelMenuActive(!showEditModelMenuActive);
        } else {
          showErrorToast("Something Went Wrong.. Please try again...");
          setButtonLoader(false);
        }
      });
  };

  // Edit Agents
  const fetchSingleAgentData = async (agentid) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/agents/edit/${agentid}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();

      if (data.success) {
        setLoader(false);
        const updatedData = {
          agent_name: data?.data?.agentInfo.agent_name,
          agent_email: data?.data?.agentInfo.agent_email,
          agent_phone: data?.data?.agentInfo.agent_phone,
          agent_category: data?.data?.agent_category,
          company_name: data?.data?.company_name,
          business_type: data?.data?.business_type,
          agent_pan: data?.data?.agent_pan,
          agent_gst: data?.data?.agent_gst,
          settled_from: data?.data?.settled_from,
          credit_amount: data?.data?.credit_amount,
          balance: data?.data?.balance,
          agent_description: data?.data?.agent_description,
          assigned_properties: data?.data?.assignedProperties,
          max_hold_time: data?.data?.max_hold_time,
          total_credit_amount: data?.data?.total_credit_amount,
          total_advance_amount: data?.data?.total_advance_amount,
          agent_information: data?.data?.agent_information,
          used_amount: data?.data?.total_used_credit,
          balance_amount: data?.data?.total_balance_credit,
          temporary_booking: data?.data?.temporary_booking,
        };

        setAddAgentData(updatedData);
        setSelectedTags(data?.data?.assignedProperties);
        setShowEditModelMenuActive(true);
        const assignedProperties = data?.data?.assignedProperties;
        // setSelectedTags(assignedProperties ? assignedProperties : []);

        // setAddItemData(updatedData);
      } else {
        setLoader(false);
      }
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  const [deleteId, setDeleteId] = useState(null);

  const removeItemConfirm = async (id) => {
    const deletedIds = {
      ids: [id],
    };
  };
  // delete agent
  const handleDeleteAgent = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/agents/delete/${deleteId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            ids: [deleteId],
          }),
        }
      );
      const data = await response.json();
      if (data.success) {
        setDeleteId(null)
        setShowCancelAgent(false);
        fetchInfo(property_id?.id);
        

        showSuccessToast(data.message);
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  // Plan Type Options
  const planTypeOptions = [
    { label: "Ep", value: "Ep" },
    { label: "CP", value: "CP" },
    { label: "MAP", value: "MAP" },
    { label: "AP", value: "AP" },
  ];

  const [planTypeSelected, setPlanTypeSelected] = useState("EP");
  const planTypeHandleSelectChange = useCallback(
    (value) => setPlanTypeSelected(value),
    []
  );

  const clickOutSideRef = useRef(null);

  const [addCategories, setAddCategories] = useState([]);

  const fetchAgentCategories = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/agent/categories/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setAddCategories(data.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (
      clickOutSideRef.current &&
      !clickOutSideRef.current.contains(event.target)
    ) {
      setShowPopUp(false);
    }
  };

  const addCategoriesArray = [
    { label: "Select Option" },
    ...addCategories.map((category) => {
      return { label: category.agent_cat_name, value: category.id };
    }),
  ];
  // ............
  const fetchPropertiesData = async () => {
    try {
      // /api/v1/user-profile
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/user-profile`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setAllProperties(data.data.properties);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchPropertiesData();
  }, []);

  const [iframeContent, setIframeContent] = useState("");
  const [iframeContentCont, setIframeContentCont] = useState("");
  const [iframePreviewBill, setIframePreviewBill] = useState("");
  const [iframePrintBill, setIframePrintBill] = useState("");
  const [showBillPreviewSingle, setShowBillPreviewSingle] = useState(false);
  const [showBillPreview, setShowBillPreview] = useState(false);

  const [showIframePopup, setShowIframePopup] = useState(false);
  const [printRequested, setPrintRequested] = useState(false);
  const [printRequestedSingle, setPrintRequestedSingle] = useState(false);

  const printIframe = (id) => {
    // setIsLoading(false);
    const iframe = document.frames
      ? document.frames[id]
      : document.getElementById(id);

    iframe.style.width = "600px";

    const iframeWindow = iframe.contentWindow || iframe;

    iframe.focus();
    iframeWindow.print();

    return false;
  };

  const getBillIframe = (id) => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/get/city/ledger/report/${id}/${property_id?.id}`,
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.text())
      .then((data) => {
        if (data) {
          setIframeContent(data);
          if (data.trim() !== "") {
            if (iframeContent.trim() !== "") {
              setIframeContent(data);
              setPrintRequested(true);

              setTimeout(() => {
                printIframe("receipt");
              }, 400);
            }
          } else {
            console.log("Empty iframe content");
          }
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const getBillIframePreview = (id) => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/get/city/ledger/report/${id}/${property_id?.id}`,
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.text())
      .then((data) => {
        if (data) {
          if (data.trim() !== "") {
            setIframeContentCont(data);
            setShowBillPreview(true);
            setShowIframePopup(true);

            setShowBillPreview(true);

            setPrintRequestedSingle(true);
          } else {
            console.log("Empty iframe content");
          }
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const [downloadUrl, setDownloadUrl] = useState("");

  const handleDownload = () => {
    const iframe = document.getElementById("receipt");
    const iframeDocument =
      iframe.contentDocument || iframe.contentWindow.document;
    const htmlContent = iframeDocument.documentElement.outerHTML;

    const pdf = new jsPDF("p", "pt", "a4");

    pdf.html(htmlContent, {
      callback: function (pdf) {
        pdf.save("receipt.pdf");
      },
      x: 10,
      y: 10,
      html2canvas: { scale: 0.5 }, // Adjust scale for better fit
    });

    iframe.style.height = "100%";
  };

  const handleUpliftMenuClick = (option, id) => {
    switch (option) {
      case "Edit Agent":
        fetchSingleAgentData(id);
        
        break;

      case "Delete":
        setShowCancelAgent(true);
        setDeleteId(id);
        break;

      case "Credit":
        navigate(`/credit/${id}`);
        break;

      case "Advance Payment":
        navigate(`/agents/${id}/advance-payments`);
        break;
    }
  };

  const handleSearch = async (value) => {
    if (!value) {
      fetchInfo(property_id?.id);
    }
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/agents/${property_id?.id}?pagination=false&search=${value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setOrders(data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="room-types-full-container">
            <div className="room-types-full-container-div">
              <div className="room-type-heading">Agents</div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                <div>
                  {permissions && permissions.includes("full_access_customer_management") && (
                    <BgOutlineThemeButton
                      children={"Categories"}
                      onClick={() =>
                        setShowAddModelMenuActive(!showModelMenuActive)
                      }
                    />
                  )}
                </div>

                <div>
                  {permissions &&
                  permissions.includes("full_access_customer_management") ? (
                    <Link
                      style={{ textDecoration: "none" }}
                      to={`/agent/addprofile`}
                    >
                      <BgThemeButton children={"Add Agent"} />
                    </Link>
                  ) : (
                    <>
                      <Tooltip content="Full Access Required">
                        <BgThemeButtonDisabled>Add Agent</BgThemeButtonDisabled>
                      </Tooltip>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div>

            <div
              className="mb-10 bg-white p-5 flex flex_gap_10"
              style={{ borderRadius: "6px" }}
            >
              <span>
                <span>
                  <b>{agents?.total_agents || 0}</b>
                </span>{" "}
                <span style={{ fontSize: "12px" }}>Total Agents </span>
              </span>

              <span>|</span>
              <span>
                <span>
                  <b> ₹{agents?.total_spend_across_agents || 0}</b>
                </span>{" "}
                <span style={{ fontSize: "12px" }}>Total Assets </span>
              </span>

              <span style={{ margin: " 0 0 0 auto" }}>
                <ApiSearchInput
                  onSearch={handleSearch}
                  placeholder={"Agent Name/ Phone"}
                />
              </span>
            </div>
              {agents && agents?.data?.length > 0 ? (
                <div className="">
                   
                  <div className="mb-10">
                    <CustomShopifyIndexTable
                      showCheckbox={false}
                      headings={[
                        "Company Name",
                        "Contact Person",
                        "Phone",
                        "Email",
                        "Category",
                        "Total Bookings",
                        "Gst",
                        "",
                        "",
                      ]}
                    >
                      {agents.data?.map((agent) => {
                        return (
                          <CustomShopifyIndexTableRow>
                            <CustomShopifyIndexTableCell
                              className="booking-third-row-one-full-div-child"
                              style={{
                                cursor: "pointer",
                                color: "#3968ed",
                                textTransform: "capitalize",
                                textDecoration: "underline",
                              }}
                            >
                              <span
                                className="pointer"
                                style={{
                                  color: "#3968ed",
                                  textTransform: "capitalize",
                                  textDecoration: "underline",
                                }}
                                // onClick={() =>
                                //   navigate(
                                //     `/credit/${agent?.agent_information}`
                                //   )
                                // }
                                onClick={() =>
                                  navigate(
                                    `/agent/details/${agent?.agent_information}`
                                  )
                                }
                              >
                                {agent.company_name}
                              </span>
                            </CustomShopifyIndexTableCell>

                            <CustomShopifyIndexTableCell>
                              {agent.agent_name}
                            </CustomShopifyIndexTableCell>
                            <CustomShopifyIndexTableCell>
                              {agent.agent_phone}
                            </CustomShopifyIndexTableCell>
                            <CustomShopifyIndexTableCell>
                              {agent.agent_email}
                            </CustomShopifyIndexTableCell>
                            <CustomShopifyIndexTableCell>
                              {agent.agent_cat_name}
                            </CustomShopifyIndexTableCell>

                            <CustomShopifyIndexTableCell>
                              {agent.total_bookings}
                            </CustomShopifyIndexTableCell>
                            <CustomShopifyIndexTableCell>
                              {agent.agent_gst}
                            </CustomShopifyIndexTableCell>
                            <CustomShopifyIndexTableCell>
                              {/* <div
                                    className="flex flex_gap_10"
                                    style={{ marginLeft: "20px" }}
                                  >
                                    <div
                                      onClick={() =>
                                        getBillIframePreview(
                                          agent.agent_information
                                        )
                                      }
                                      className="pointer"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                      >
                                        <path
                                          d="M7 8.75C7.9665 8.75 8.75 7.9665 8.75 7C8.75 6.0335 7.9665 5.25 7 5.25C6.0335 5.25 5.25 6.0335 5.25 7C5.25 7.9665 6.0335 8.75 7 8.75Z"
                                          fill="#3968ED"
                                        />
                                        <path
                                          d="M13.5361 6.85125C13.0215 5.52022 12.1282 4.36914 10.9665 3.54029C9.80489 2.71143 8.42584 2.24113 6.99981 2.1875C5.57379 2.24113 4.19473 2.71143 3.03309 3.54029C1.87145 4.36914 0.978134 5.52022 0.463564 6.85125C0.428812 6.94737 0.428812 7.05263 0.463564 7.14875C0.978134 8.47978 1.87145 9.63086 3.03309 10.4597C4.19473 11.2886 5.57379 11.7589 6.99981 11.8125C8.42584 11.7589 9.80489 11.2886 10.9665 10.4597C12.1282 9.63086 13.0215 8.47978 13.5361 7.14875C13.5708 7.05263 13.5708 6.94737 13.5361 6.85125ZM6.99981 9.84375C6.43737 9.84375 5.88756 9.67697 5.41991 9.36449C4.95226 9.05202 4.58777 8.60788 4.37253 8.08826C4.15729 7.56863 4.10098 6.99685 4.21071 6.44521C4.32043 5.89358 4.59127 5.38687 4.98898 4.98917C5.38668 4.59146 5.89339 4.32062 6.44503 4.21089C6.99666 4.10117 7.56844 4.15748 8.08807 4.37272C8.6077 4.58795 9.05183 4.95245 9.36431 5.4201C9.67678 5.88775 9.84356 6.43756 9.84356 7C9.84241 7.75385 9.54243 8.4765 9.00937 9.00956C8.47631 9.54261 7.75367 9.84259 6.99981 9.84375Z"
                                          fill="#3968ED"
                                        />
                                      </svg>
                                    </div>
                                    <div
                                      className="pointer"
                                      onClick={() =>
                                        getBillIframe(agent.agent_information)
                                      }
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 14 12"
                                        fill="none"
                                      >
                                        <path
                                          d="M11.0007 2.66667H3.00065V0H11.0007V2.66667ZM11.0007 6.33333C11.1895 6.33333 11.348 6.26933 11.476 6.14133C11.604 6.01333 11.6678 5.85511 11.6673 5.66667C11.6673 5.47778 11.6033 5.31933 11.4753 5.19133C11.3473 5.06333 11.1891 4.99956 11.0007 5C10.8118 5 10.6533 5.064 10.5253 5.192C10.3973 5.32 10.3335 5.47822 10.334 5.66667C10.334 5.85556 10.398 6.014 10.526 6.142C10.654 6.27 10.8122 6.33378 11.0007 6.33333ZM9.66732 10.6667V8H4.33398V10.6667H9.66732ZM11.0007 12H3.00065V9.33333H0.333984V5.33333C0.333984 4.76667 0.528429 4.29156 0.917318 3.908C1.30621 3.52444 1.77843 3.33289 2.33398 3.33333H11.6673C12.234 3.33333 12.7091 3.52511 13.0927 3.90867C13.4762 4.29222 13.6678 4.76711 13.6673 5.33333V9.33333H11.0007V12Z"
                                          fill="#3968ED"
                                        />
                                      </svg>
                                    </div>
                                  </div>

                                  {showBillPreview && (
                                    <>
                                      <iframe
                                        srcDoc={iframeContentCont}
                                        id="receipt"
                                        style={{ display: "none" }}
                                        title="Receipt"
                                      />
                                      <iframe
                                        srcDoc={iframeContent}
                                        id="receipt"
                                        style={{ display: "none" }}
                                        title="Receipt"
                                      />
                                    </>
                                  )} */}
                            </CustomShopifyIndexTableCell>

                            <CustomShopifyIndexTableCell>
                              {permissions &&
                                permissions.includes(
                                  "full_access_customer_management"
                                ) && (
                                  <>
                                    <UpliftMenuButton
                                      handleUpliftMenuClick={
                                        handleUpliftMenuClick
                                      }
                                      menuOptions={[
                                        "Edit Agent",
                                        "Credit",
                                        "Advance Payment",
                                        "Delete",
                                      ]}
                                      id={agent.agent_information}
                                    />
                                  </>
                                )}
                            </CustomShopifyIndexTableCell>
                          </CustomShopifyIndexTableRow>
                        );
                      })}
                    </CustomShopifyIndexTable>
                  </div>

                  <div className="w-fit-content  mx-auto">
                    <Pagination
                      type={"advance"}
                      currentPage={agents.current_page}
                      totalPages={agents.last_page}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </div>
              ) : (
                <NoDataFound />
              )}
            </div>
          </div>

          {/* Add Button Click */}
          {showAddModelMenuActive && (
            <AddCategoryAgent
              setShowAddModelMenuActive={setShowAddModelMenuActive}
            />
          )}

          {/* ACompany Credits */}
          {companyCredits && (
            <AddCompanyCredits setShowAddModelMenuActive={setCompanyCredits} />
          )}

          {/* ACompany Credits */}
          {advancePayment && (
            <AddAdvancePayments setShowAddModelMenuActive={setAdvancePayment} />
          )}

          {/* Edit Button Click */}
          {showEditModelMenuActive && (
            <>
              {/* <div className="bg-backdrop">
                <section className="room-rate-plan-edit-model-container">
                  <h4>Edit Agent</h4>
                  <div className="edit-rooms-form-container">
                    <div className="form-container-div-half">
                      <div className="form-container-div">
                        <VerticalInputFieldCustom
                          type={"text"}
                          required={true}
                          name={"agent_name"}
                          className="textfield"
                          focused={false}
                          titleName="Name"
                          onChange={handleChangeAgentData}
                          autoComplete="off"
                          value={addAgentData.agent_name}
                        />
                      </div>
                      <div className="form-container-div">
                        <VerticalInputFieldCustom
                          type={"number"}
                          required={true}
                          name={"agent_phone"}
                          className="textfield"
                          focused={false}
                          titleName="Phone"
                          onChange={handleChangeAgentData}
                          autoComplete="off"
                          value={addAgentData.agent_phone}
                        />
                      </div>
                    </div>
                    <div className="form-container-div-half">
                      <div className="form-container-div">
                        <VerticalInputFieldCustom
                          type={"email"}
                          required={true}
                          name={"agent_email"}
                          className="textfield"
                          focused={false}
                          titleName="Email"
                          onChange={handleChangeAgentData}
                          autoComplete="off"
                          value={addAgentData.agent_email}
                        />
                      </div>

                      <div className="form-container-div">
                        <VerticalInputSelectCustomCopy
                          options={addCategoriesArray}
                          type={"text"}
                          required={true}
                          name={"agent_category"}
                          className="textfield"
                          focused={false}
                          titleName="Agent Category"
                          onChange={handleChangeAgentData}
                          autoComplete="off"
                          value={addAgentData.agent_category}
                        />
                      </div>

                      
                    </div>

                    <div className="form-container-div-half">
                      <div className="form-container-div">
                        <VerticalInputFieldCustom
                          // type={"number"}
                          required={true}
                          name={"total_credit_amount"}
                          className="textfield"
                          focused={false}
                          titleName="Total Credit Limit"
                          onChange={handleChangeAgentData}
                          autoComplete="off"
                          value={addAgentData.total_credit_amount || 0}
                        />
                      </div>
                      <div className="form-container-div">
                        <VerticalInputFieldCustom
                          // type={"number"}
                          required={true}
                          name={"agent_gst"}
                          className="textfield"
                          focused={false}
                          titleName="GST"
                          onChange={handleChangeAgentData}
                          autoComplete="off"
                          value={addAgentData.agent_gst}
                        />
                      </div>

                      <div className="form-container-div">
                        <VerticalInputFieldCustom
                          disabled={true}
                          type={"number"}
                          required={true}
                          name={"total_advance_amount"}
                          className="textfield"
                          focused={false}
                          titleName="Total Advance Payment"
                          onChange={handleChangeAgentData}
                          autoComplete="off"
                          value={addAgentData.total_advance_amount || 0}
                        />
                      </div>
                    </div>
                    <div className="form-container-div-half">
                      <div className="form-container-div">
                        <div
                          onClick={handleToggle8}
                          aria-expanded={open8}
                          aria-controls="basic-collapsible"
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontWeight: "700",
                            fontSize: "14px",
                          }}
                        >
                          <div>Assign Hotel</div>
                        </div>

                        <div className="edit-rooms-form-container">
                          <div className="form-container-div">
                            <div style={{ marginBottom: "4px" }}>
                              Select Property
                              <span style={{ color: "red" }}>*</span>
                            </div>
                            <select
                              style={{
                                width: "100%",
                                minHeight: "2.25rem",
                                border: "1px solid rgba(171, 177, 186, 1)",
                                borderRadius: "3px",
                              }}
                              onChange={(e) => addAssignHotel(e)}
                            >
                              <option value="">Select Option</option>
                              {allProperties?.map((x) => {
                                return (
                                  <option
                                    id={x.property_id}
                                    key={x.property_id}
                                    value={x.property_name}
                                  >
                                    {x.property_name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>

                          <div
                            style={{
                              marginTop: "10px",
                              display: "flex",
                              flexWrap: "wrap",
                            }}
                          >
                            {selectedTags &&
                              selectedTags?.length > 0 &&
                              selectedTags?.map((tag) => {
                                return (
                                  <div
                                    style={{
                                      backgroundColor: "#f0f0f0",
                                      padding: "5px",
                                      borderRadius: "5px",
                                      margin: "5px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {tag?.name}
                                    <span
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        removePropertyHandler(tag.id)
                                      }
                                    >
                                      <Icon source={CancelMajor} />
                                    </span>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                      <div className="form-container-div">
                        <div style={{ marginBottom: "7px" }}>
                          <CustomToggleButton
                            positive={"Enable"}
                            negative={"Disable"}
                            byDefaultEnable={
                              addAgentData.temporary_booking === 1
                                ? true
                                : false
                            }
                            func={temporaryBookingsHandle}
                            title={"Temporary Bookings"}
                          />
                        </div>
                        {showMaxHold && (
                          <div className="form-container-div-full">
                            <VerticalInputFieldCustom
                              required={true}
                              name={"max_hold_time"}
                              className="textfield"
                              focused={false}
                              titleName="Max Hold Time"
                              onChange={handleChangeAgentData}
                              autoComplete="off"
                              value={addAgentData.max_hold_time}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <BgThemeButton onClick={handleUpdateAgents} loader={loader}>
                      Update
                    </BgThemeButton>
                    <Button onClick={() => setShowEditModelMenuActive(false)}>
                      Discard
                    </Button>
                  </div>
                </section>
              </div> */}

              <EditAgentPopup
               show={showEditModelMenuActive}
               setShow={setShowEditModelMenuActive}
               addAgentData={addAgentData}
               setAddAgentData={setAddAgentData}
               userToken={userToken}
               fetchInfo={fetchInfo}
               property={property_id}
               selectedTags={selectedTags}
               setSelectedTags={setSelectedTags}
              
              />
            </>
          )}

          {showCancelAgent && (
            <>
              <ConfirmPopup
                heading={"Are You Sure ?"}
                subHeading={"Do You Want To Delete"}
                showPopup={showCancelAgent}
                setShowPopup={setShowCancelAgent}
                handleConfirmClick={handleDeleteAgent}
              />
            </>
          )}

          {iframeContentCont && showIframePopup && (
            <>
              <div className="bg-backdrop">
                <section className="communication_iframe_container">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "15px",
                      padding: "0 25px",
                    }}
                  >
                    <div
                      className="add-ota-model-container-heading"
                      style={{ display: "flex", gap: "10px" }}
                    >
                      Report
                      <div
                        onClick={() => printIframe("receipt")}
                        style={{ cursor: "pointer" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="12"
                          viewBox="0 0 14 12"
                          fill="none"
                        >
                          <path
                            d="M11.0007 2.66667H3.00065V0H11.0007V2.66667ZM11.0007 6.33333C11.1895 6.33333 11.348 6.26933 11.476 6.14133C11.604 6.01333 11.6678 5.85511 11.6673 5.66667C11.6673 5.47778 11.6033 5.31933 11.4753 5.19133C11.3473 5.06333 11.1891 4.99956 11.0007 5C10.8118 5 10.6533 5.064 10.5253 5.192C10.3973 5.32 10.3335 5.47822 10.334 5.66667C10.334 5.85556 10.398 6.014 10.526 6.142C10.654 6.27 10.8122 6.33378 11.0007 6.33333ZM9.66732 10.6667V8H4.33398V10.6667H9.66732ZM11.0007 12H3.00065V9.33333H0.333984V5.33333C0.333984 4.76667 0.528429 4.29156 0.917318 3.908C1.30621 3.52444 1.77843 3.33289 2.33398 3.33333H11.6673C12.234 3.33333 12.7091 3.52511 13.0927 3.90867C13.4762 4.29222 13.6678 4.76711 13.6673 5.33333V9.33333H11.0007V12Z"
                            fill="#3968ED"
                          />
                        </svg>
                      </div>
                      <div
                        onClick={handleDownload}
                        style={{ cursor: "pointer" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                        >
                          <path
                            d="M6.99957 10.1107L3.11068 6.22179L4.19957 5.09401L6.22179 7.11623V0.777344H7.77734V7.11623L9.79957 5.09401L10.8885 6.22179L6.99957 10.1107ZM2.3329 13.2218C1.90512 13.2218 1.53879 13.0693 1.2339 12.7645C0.929012 12.4596 0.776827 12.0935 0.777345 11.6662V9.3329H2.3329V11.6662H11.6662V9.3329H13.2218V11.6662C13.2218 12.094 13.0693 12.4603 12.7645 12.7652C12.4596 13.0701 12.0935 13.2223 11.6662 13.2218H2.3329Z"
                            fill="#3968ED"
                          />
                        </svg>
                      </div>
                      {downloadUrl && (
                        <a href={downloadUrl} download="receipt.html">
                          Download
                        </a>
                      )}
                    </div>
                    <div
                      onClick={() => setShowIframePopup(false)}
                      style={{ cursor: "pointer" }}
                    >
                      <Icon source={CancelMajor} color="base" />
                    </div>
                  </div>
                  <iframe
                    srcDoc={iframeContentCont}
                    id="receipt"
                    // style={{ display: "none" }}
                    title="Receipt"
                  />
                  {/* <PreviewCommunicationVoucher uniquebookingid={bookingId} /> */}
                </section>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
export default NewAgents;
