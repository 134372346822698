import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { BgOutlineThemeButton, BgThemeButton } from "../../../UI/Buttons";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../assets/toastUtils";
import VerticalInputFieldCustom from "../../VerticalInputFieldCustom.jsx";
import {
  grandTotalForInclusive,
  grandTotalForExclusive,
  taxAmountForInclusive,
  taxAmountForExclusive,
  calculateSubtotalForInclusive,
  calculateSubtotalForExclusive,
} from "../calculationFunctions/CalculationFunctions.jsx";
import { calculateTotal } from "../calculationFunctions/CalculateKeyTotalFromArrayOfObjects";
import { getDecryptedData } from "../../../../utils/encryptStorage.js";

const ModifyReservationConfirmAmendStay = ({
  bookingData,
  addOnCost,
  reasons,
  setReasons,
  setShowModifyPopup,
  handleReasonChange,
  getBookingData,
  fetchBookingDetails,
  propertyCurrentDate,
  formatDate,
  formatDateMMDDYYYY,
  calculateNumberOfNights,
  calculateToDatePlus,
  handlePopupClose
}) => {
  const extractPrices = (paxes) => {
    let childPrice = 0;
    let extraAdultPrice = 0;
  
    // Check if paxes is an array before iterating over it
    if (Array.isArray(paxes)) {
      paxes.forEach((pax) => {
        // Use adhoc_price if available; otherwise, use price
        const price = parseFloat(pax.adhoc_price) || parseFloat(pax.price);
        
        if (pax.pax_type === "child") {
          childPrice += price;
        } else if (pax.pax_type === "adult") {
          extraAdultPrice += price;
        }
      });
    } else {
      console.error("Invalid paxes data:", paxes);
    }
  
    return { childPrice, extraAdultPrice };
  };
  const [loader, setLoader] = useState(false);
  const property_id = useSelector((state) => state.property.value);
  const userToken = getDecryptedData('encryptToken');
  const navigate = useNavigate();
  const [amendStayData, setAmentStayData] = useState([]);
  useEffect(() => {
    if (bookingData && bookingData.items) {
      const newEditedRoomCategory = bookingData.items.map((item) => ({
        room_category: item.room_type_name,
        changed_room_category: "",
        room_id: item.room_id,
        rate_plan_id: item.rate_plan_id,
        meal_plan: item.room_plan,
        room_rate_per_night: item.room_rate,
        ad_Hoc: item.ad_Hoc || "",
        room_rate: [],
        paxes: item.paxes,
        tax_type: item.tax_type,
        no_of_rooms: item.no_of_rooms,
        no_of_nights: item.no_nights,
        booking_id: item.id,
        discount_rate: item.discount_rate,
        id: item.rate_plan_id,
        new_room_rate: "",
        old_tax_amount: item.tax_amount,
        old_discount_amount: item.discount_amount,
        old_grand_total: item.room_grand_total,
        old_sub_total: item.room_total_amount,
        booking_item_id: item.id,
      }));

      setAmentStayData(newEditedRoomCategory);
      setActiveIndex(Array(newEditedRoomCategory.length).fill(false));
      localStorage.setItem(
        "amendStayData",
        JSON.stringify(newEditedRoomCategory)
      );
    }
  }, [bookingData]);

  //-----early check out
  const [showCheckOutCalculations, setShowCheckOutCalculations] =
    useState(false);
  const [amendStayTotal, setAmendStayTotal] = useState(0);

  const [earlyCheckOutDate, setEarlyCheckOutDate] = useState("");

  const handleEarlyCheckOutChange = (e) => {
    setAmendStayTotal("");
    setShowCheckOutCalculations(false);
    const selectedDate = new Date(e.target.value);
    const checkInDate = new Date(bookingData.check_in);
    const checkOutDate = new Date(bookingData.check_out);

    if (selectedDate < checkInDate || selectedDate > checkOutDate) {
      showErrorToast(
        "Please select a date between booking check-in and check-out dates."
      );
      setEarlyCheckOutDate("");
    } else {
      setEarlyCheckOutDate(selectedDate);
    }
  };

  const handleEarlyCheckOutCalculate = (e) => {
    if (!earlyCheckOutDate) {
      showErrorToast("Please select early check-out date");
      return;
    }
   

    const no_of_night = calculateNumberOfNights(
      new Date(bookingData?.check_in),
      new Date(earlyCheckOutDate)
    );

    const rooms = amendStayData.map((item, index) => {
      const { childPrice, extraAdultPrice } = extractPrices(item.paxes);

      const totalDiscountAmount = +item.old_discount_amount;
      const taxRate = +item.room_rate_per_night > 7500 ? 18 : 12;
      return {
        BookingItemId: item.booking_item_id,
        RoomID: item.room_id,
        NumberOfNights: no_of_night,
        Rate: item.room_rate_per_night,
        TaxRate: taxRate,
        DiscountAmount: totalDiscountAmount,
        NetTotal:
          item.tax_type === "inclusive"
            ? grandTotalForInclusive(
                +item.room_rate_per_night,
                +no_of_night,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +totalDiscountAmount
              )
            : grandTotalForExclusive(
                +item.room_rate_per_night,
                +no_of_night,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +totalDiscountAmount
              ),
        TaxAmount:
          item.tax_type === "inclusive"
            ? taxAmountForInclusive(
                +item.room_rate_per_night,
                +no_of_night,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +totalDiscountAmount
              )
            : taxAmountForExclusive(
                +item.room_rate_per_night,
                +no_of_night,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +totalDiscountAmount
              ),
        TaxbleTotal:
          item.tax_type === "inclusive"
            ? calculateSubtotalForInclusive(
                +item.room_rate_per_night,
                +no_of_night,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +totalDiscountAmount
              )
            : calculateSubtotalForExclusive(
                +item.room_rate_per_night,
                +no_of_night,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +totalDiscountAmount
              ),
      };
    });

    const requestBody = {
      reservationId: bookingData.unique_booking_id,
      folioId: 123,
      requestType: "early_checkout",
      CheckOutDate: formatDate(earlyCheckOutDate),
      bookingNetTotal: +calculateTotal(rooms, "NetTotal") + +addOnCost.NetTotal,
      bookingTaxableAmount:
        +calculateTotal(rooms, "TaxbleTotal") + +addOnCost.taxableAmount,
      bookingTaxAmount:
        +calculateTotal(rooms, "TaxAmount") + +addOnCost.taxAmount,
      Room: rooms,
    };

    setAmendStayTotal(requestBody.bookingNetTotal);
    setShowCheckOutCalculations(true);
  };

  const handleEarlyCheckOutSubmit = async (e) => {
    if (reasons.amendStayReason === "") {
      showErrorToast("Please enter the reason for early checkout");
      return;
    }

    // this function will perform the addition of all the child paxes and adult paxes so no need to multiply with no of child and no of adults
    if (!earlyCheckOutDate) {
      showErrorToast("Please select early check-out date");
      return;
    }
   

    const no_of_night = calculateNumberOfNights(
      new Date(bookingData?.check_in),
      new Date(earlyCheckOutDate)
    );
    // const amendStayForRoom = amendStayDiscount / bookingData?.items?.length;
    const rooms = amendStayData.map((item, index) => {
      const { childPrice, extraAdultPrice } = extractPrices(item.paxes);
      // const discountedRatePerNight = +item.discount_amount / +item.no_nights;
      // const totalDiscountAmount = discountedRatePerNight * no_of_night;
      const totalDiscountAmount = item.old_discount_amount;
      const taxRate = +item.room_rate_per_night > 7500 ? 18 : 12;
      return {
        BookingItemId: item.booking_item_id,
        NumberOfNights: no_of_night,
        Rate: item.room_rate_per_night,
        RoomID: item.room_id,
        TaxRate: taxRate,
        RoomDiscount: totalDiscountAmount,
        NetTotal:
          item.tax_type === "inclusive"
            ? grandTotalForInclusive(
                +item.room_rate_per_night,
                +no_of_night,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +totalDiscountAmount
              )
            : grandTotalForExclusive(
                +item.room_rate_per_night,
                +no_of_night,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +totalDiscountAmount
              ),
        TaxAmount:
          item.tax_type === "inclusive"
            ? taxAmountForInclusive(
                +item.room_rate_per_night,
                +no_of_night,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +totalDiscountAmount
              )
            : taxAmountForExclusive(
                +item.room_rate_per_night,
                +no_of_night,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +totalDiscountAmount
              ),
        TaxbleTotal:
          item.tax_type === "inclusive"
            ? calculateSubtotalForInclusive(
                +item.room_rate_per_night,
                +no_of_night,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +totalDiscountAmount
              )
            : calculateSubtotalForExclusive(
                +item.room_rate_per_night,
                +no_of_night,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +totalDiscountAmount
              ),
      };
    });

    const requestBody = {
      reservationId: bookingData.unique_booking_id,
      requestType: "early_checkout",
      CheckOutDate: formatDate(earlyCheckOutDate),
      bookingNetTotal: +calculateTotal(rooms, "NetTotal") + +addOnCost.NetTotal,
      bookingTaxableAmount:
        +calculateTotal(rooms, "TaxbleTotal") + +addOnCost.taxableAmount,
      bookingTaxAmount:
        +calculateTotal(rooms, "TaxAmount") + +addOnCost.taxAmount,
      bookingDueAmount:
        +(+calculateTotal(rooms, "NetTotal") + +addOnCost.NetTotal) -
        +bookingData.paid_amount,
      Room: rooms,
      bookingDiscountAmount:
        +calculateTotal(rooms, "RoomDiscount") + +addOnCost.discountAmount,
      requestReason: reasons.amendStayReason,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
      body: JSON.stringify(requestBody),
    };

    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/reservation/modif/stay/${property_id.id}`,
        requestOptions
      );
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.description || data.error || "Unknown error");
      }

      showSuccessToast("Stay has been reduced successfully");
      setAmendStayTotal(0);
      setShowCheckOutCalculations(false);
      fetchBookingDetails();
      handlePopupClose();
      setEarlyCheckOutDate("");
      setReasons({});
      getBookingData(bookingData.unique_booking_id);
      setLoader(false);
      return data;
    } catch (error) {
      showErrorToast(error.message || error.description);
      setLoader(false);
      return null;
    }
  };
  // -----------late check out----------------------

  const [lateCheckOutDate, setLateCheckOutDate] = useState("");

  const handleLateCheckOutChange = async (e) => {
    setAmendStayTotal("");
    setShowCheckOutCalculations(false);

    const selectedDate = new Date(e.target.value);
    const checkOutDate = new Date(bookingData.check_out);

    if (selectedDate < checkOutDate) {
      showErrorToast(
        "Please select a date later than the current checkout date"
      );
      setLateCheckOutDate("");
    } else {
      const requestBody = {
        reservationId: bookingData.unique_booking_id,
        requestType: "check_availability",
        CheckInDate: formatDate(selectedDate),
        CheckOutDate: formatDate(checkOutDate),
        requestReason: "late_checkout",
        Room: bookingData.items.map((room) => ({
          BookingItemId: room.booking_item_id,
          RoomID: room.room_id,
          NumberOfRooms: room.no_of_rooms,
        })),
      };

      try {
        setLoader(true);
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/v1/reservation/modif/stay/${property_id?.id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
            body: JSON.stringify(requestBody),
          }
        );

        const data = await response.json();

        if (data.success) {
          showSuccessToast("Selected date is available for late checkout");
          setLateCheckOutDate(selectedDate);
          setLoader(false);
        } else {
          showErrorToast("Selected date is not available for late checkout");
          setLateCheckOutDate("");
          setLoader(false);
        }
      } catch (error) {
        // Handle any network or server errors
        console.error("Error while checking availability:", error);
        showErrorToast(
          "An error occurred while checking availability. Please try again later."
        );
        setLateCheckOutDate("");
        setLoader(false);
      }
    }
  };

  const handleLateCheckOutCalculate = (e) => {
    if (!lateCheckOutDate) {
      showErrorToast("Please select a date for late checkout");
      return;
    }
   

    const no_of_night = calculateNumberOfNights(
      new Date(bookingData?.check_in),
      new Date(lateCheckOutDate)
    );
    // const amendStayForRoom = amendStayDiscount / bookingData?.items?.length;

    const rooms = amendStayData.map((item, index) => {
      const { childPrice, extraAdultPrice } = extractPrices(item.paxes);
      // console.log(item.paxes ,"item.paxes");
      // console.log(childPrice, extraAdultPrice ,"hiii");
      // const discountedRatePerNight = +item.discount_amount / +item.no_nights;
      // const totalDiscountAmount = discountedRatePerNight * no_of_night;
      const totalDiscountAmount = item.old_discount_amount;
      const taxRate = +item.room_rate_per_night > 7500 ? 18 : 12;
      return {
        BookingItemId: item.booking_item_id,
        NumberOfNights: no_of_night,
        Rate: item.room_rate_per_night,
        TaxRate: taxRate,
        RoomDiscount: totalDiscountAmount,
        NetTotal:
          item.tax_type === "inclusive"
            ? grandTotalForInclusive(
                +item.room_rate_per_night,
                +no_of_night,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +totalDiscountAmount
              )
            : grandTotalForExclusive(
                +item.room_rate_per_night,
                +no_of_night,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +totalDiscountAmount
              ),
        TaxAmount:
          item.tax_type === "inclusive"
            ? taxAmountForInclusive(
                +item.room_rate_per_night,
                +no_of_night,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +totalDiscountAmount
              )
            : taxAmountForExclusive(
                +item.room_rate_per_night,
                +no_of_night,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +totalDiscountAmount
              ),
        TaxbleTotal:
          item.tax_type === "inclusive"
            ? calculateSubtotalForInclusive(
                +item.room_rate_per_night,
                +no_of_night,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +totalDiscountAmount
              )
            : calculateSubtotalForExclusive(
                +item.room_rate_per_night,
                +no_of_night,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +totalDiscountAmount
              ),
      };
    });

    const requestBody = {
      reservationId: bookingData.unique_booking_id,
      requestType: "extend_stay",
      CheckInDate: bookingData.check_out,
      CheckOutDate: formatDate(lateCheckOutDate),
      bookingNetTotal: +calculateTotal(rooms, "NetTotal") + +addOnCost.NetTotal,
      bookingTaxableAmount:
        +calculateTotal(rooms, "TaxbleTotal") + +addOnCost.taxableAmount,
      bookingTaxAmount:
        +calculateTotal(rooms, "TaxAmount") + +addOnCost.taxAmount,
      Room: rooms,
    };

    setAmendStayTotal(requestBody.bookingNetTotal);
    setShowCheckOutCalculations(true);
  };
  const handleLateCheckOutSubmit = async (e) => {
    if (!lateCheckOutDate) {
      showErrorToast("Please select a date for late checkout");
      return;
    }
    if (reasons.amendStayReason === "") {
      showErrorToast("Please enter the reason for late checkout");
      return;
    }
    if (!lateCheckOutDate) {
      showErrorToast("Please select the late checkout date");
      return;
    }
    

    const no_of_night = calculateNumberOfNights(
      new Date(bookingData?.check_in),
      new Date(lateCheckOutDate)
    );

    const rooms = amendStayData.map((item, index) => {
      const { childPrice, extraAdultPrice } = extractPrices(item.paxes);

      const totalDiscountAmount = item.old_discount_amount;
      const taxRate = +item.room_rate_per_night > 7500 ? 18 : 12;
      return {
        BookingItemId: item.booking_item_id,
        NumberOfNights: no_of_night,
        Rate: item.room_rate_per_night,
        TaxRate: taxRate,
        RoomID: item.room_id,
        RoomDiscount: totalDiscountAmount ? totalDiscountAmount : 0,
        NetTotal:
          item.tax_type === "inclusive"
            ? grandTotalForInclusive(
                +item.room_rate_per_night,
                +no_of_night,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +totalDiscountAmount
              )
            : grandTotalForExclusive(
                +item.room_rate_per_night,
                +no_of_night,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +totalDiscountAmount
              ),
        TaxAmount:
          item.tax_type === "inclusive"
            ? taxAmountForInclusive(
                +item.room_rate_per_night,
                +no_of_night,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +totalDiscountAmount
              )
            : taxAmountForExclusive(
                +item.room_rate_per_night,
                +no_of_night,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +totalDiscountAmount
              ),
        TaxbleTotal:
          item.tax_type === "inclusive"
            ? calculateSubtotalForInclusive(
                +item.room_rate_per_night,
                +no_of_night,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +totalDiscountAmount
              )
            : calculateSubtotalForExclusive(
                +item.room_rate_per_night,
                +no_of_night,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +totalDiscountAmount
              ),
      };
    });

    const requestBody = {
      reservationId: bookingData.unique_booking_id,
      requestType: "extend_stay",
      CheckInDate: bookingData.check_out,
      CheckOutDate: formatDate(lateCheckOutDate),
      bookingNetTotal: +calculateTotal(rooms, "NetTotal") + +addOnCost.NetTotal,
      bookingTaxableAmount:
        +calculateTotal(rooms, "TaxbleTotal") + +addOnCost.taxableAmount,
      bookingTaxAmount:
        +calculateTotal(rooms, "TaxAmount") + +addOnCost.taxAmount,
      bookingDueAmount:
        +(+calculateTotal(rooms, "NetTotal") + +addOnCost.NetTotal) -
        +bookingData.paid_amount,
      Room: rooms,
      requestReason: reasons.amendStayReason,
      bookingDiscountAmount: calculateTotal(rooms, "RoomDiscount"),
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
      body: JSON.stringify(requestBody),
    };

    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/reservation/modif/stay/${property_id.id}`,
        requestOptions
      );
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.description || data.error || "Unknown error");
      }

      showSuccessToast("Stay has been extended successfully");
      setAmendStayTotal(0);
      setShowCheckOutCalculations(false);
      handlePopupClose();
      fetchBookingDetails();
      setLateCheckOutDate("");
      setReasons({});
      getBookingData(bookingData.unique_booking_id);
      setLoader(false);

      return data;
    } catch (error) {
      showErrorToast(error.message || error.description || "Unknown error");
      setLoader(false);
      return null;
    }
  };

  const [activeIndex, setActiveIndex] = useState([]);

  const handleAmendStayCheckboxChange = (e, roomIndex) => {
    setAmendStayTotal("");
    setShowCheckOutCalculations(false);
    const checked = e.target.checked;
    if (checked) {
      setActiveIndex((prev) => [...prev, roomIndex]);
    } else {
      setActiveIndex((prev) => prev.filter((index) => index !== roomIndex));
      const storedData = JSON.parse(localStorage.getItem("amendStayData"));

      setAmentStayData((prevEditMealPlans) => {
        const updatedData = [...prevEditMealPlans];
        updatedData[roomIndex] = storedData[roomIndex];
        return updatedData;
      });
    }
  };
  const handleAmendStayRateChange = (e, roomIndex) => {
    setAmendStayTotal("");
    setShowCheckOutCalculations(false);
    const { name, value } = e.target;
    setAmentStayData((prev) => {
      const updatedData = [...prev];
      updatedData[roomIndex] = {
        ...updatedData[roomIndex],
        [name]: value,
      };
      return updatedData;
    });
  };
  const handleAmendStayDiscountChangeSingle = (e, roomIndex) => {
    setAmendStayTotal("");
    setShowCheckOutCalculations(false);
    const { name, value } = e.target;

    setAmentStayData((prev) => {
      const updatedData = [...prev];
      updatedData[roomIndex] = {
        ...updatedData[roomIndex],
        [name]: value,
      };
      return updatedData;
    });
  };

  //======================New Code=====================
  const [selectedOption, setSelectedOption] = useState("earlyCheckOut");
  const handleAmendStaySelectCheckboxChange = (type) => {
    setSelectedOption(type);
    setShowCheckOutCalculations(false);
    setAmendStayTotal(0);
    setLateCheckOutDate("");
    setEarlyCheckOutDate("");
    setLateCheckInDate("");
    setEarlyCheckInDate("");
  };

  // ===== early Check in  handlers====
  const [earlyCheckInDate, setEarlyCheckInDate] = useState("");
  const [lateCheckInDate, setLateCheckInDate] = useState("");

  const handleEarlyCheckInDateChange = async (e) => {
    setAmendStayTotal("");
    setShowCheckOutCalculations(false);
    const selectedDate = new Date(e.target.value);
    const checkInDate = new Date(bookingData.check_in);

    if (selectedDate >= checkInDate) {
      showErrorToast("Please select a date before the booking check-in date.");
      setEarlyCheckInDate("");
    } else {
      const requestBody = {
        reservationId: bookingData.unique_booking_id,
        requestType: "check_availability",
        CheckInDate: formatDate(selectedDate),
        CheckOutDate: bookingData.check_out,
        requestReason: "late_checkout",
        Room: bookingData.items.map((room) => ({
          BookingItemId: room.id,
          RoomID: room.room_id,
          NumberOfRooms: room.no_of_rooms,
        })),
      };

      try {
        setLoader(true);
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/v1/reservation/modif/stay/${property_id?.id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
            body: JSON.stringify(requestBody),
          }
        );

        const data = await response.json();

        if (data.success) {
          showSuccessToast("Selected date is available for Early check-in");
          setEarlyCheckInDate(selectedDate);
          setLoader(false);
        } else {
          showErrorToast("Selected date is not available for Early check-in");
          setEarlyCheckInDate("");
          setLoader(false);
        }
      } catch (error) {
        // Handle any network or server errors
        console.error("Error while checking availability:", error);
        showErrorToast(
          "An error occurred while checking availability. Please try again later."
        );
        setEarlyCheckInDate("");
        setLoader(false);
      }
    }
  };

  // ===== late Check in handlers====
  const handleLateCheckInDateChange = async (e) => {
    setAmendStayTotal("");
    setShowCheckOutCalculations(false);
    const selectedDate = new Date(e.target.value);
    const checkInDate = new Date(bookingData.check_in);
    const checkOutDate = new Date(bookingData.check_out);

    if (selectedDate <= checkInDate || selectedDate >= checkOutDate) {
      showErrorToast(
        "Please select a date after the booking check-in date and before the check-out date."
      );
      setLateCheckInDate("");
    } else {
      // setLateCheckInDate(selectedDate);
      const requestBody = {
        reservationId: bookingData.unique_booking_id,
        requestType: "check_availability",
        CheckInDate: formatDate(selectedDate),
        CheckOutDate: bookingData.check_out,
        requestReason: "late_checkout",
        Room: bookingData.items.map((room) => ({
          BookingItemId: room.id,
          RoomID: room.room_id,
          NumberOfRooms: room.no_of_rooms,
        })),
      };

      try {
        setLoader(true);
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/v1/reservation/modif/stay/${property_id?.id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
            body: JSON.stringify(requestBody),
          }
        );

        const data = await response.json();

        if (data.success) {
          showSuccessToast("Selected date is available for late check-in");
          setLateCheckInDate(selectedDate);
          setLoader(false);
        } else {
          showErrorToast("Selected date is not available for late check-in");
          setLateCheckInDate("");
          setLoader(false);
        }
      } catch (error) {
        // Handle any network or server errors
        console.error("Error while checking availability:", error);
        showErrorToast(
          "An error occurred while checking availability. Please try again later."
        );
        setLateCheckInDate("");
      }
    }
  };

  // === submit function handlers===

  const handleEarlyCheckInCalculate = (e) => {
    if (!earlyCheckInDate) {
      showErrorToast("Please select a date for early checkout");
      return;
    }
    

    const no_of_night = calculateNumberOfNights(
      new Date(earlyCheckInDate),
      new Date(bookingData.check_out)
    );

    console.log(bookingData.check_out, "bookingData.check_out");

    console.log(no_of_night, "no_of_night");
    // const amendStayForRoom = amendStayDiscount / bookingData?.items?.length;

    const rooms = amendStayData.map((item, index) => {
      const { childPrice, extraAdultPrice } = extractPrices(item.paxes);
      console.log(childPrice, extraAdultPrice);
      // const discountedRatePerNight = +item.discount_amount / +item.no_nights;
      // const totalDiscountAmount = discountedRatePerNight * no_of_night;
      const totalDiscountAmount = item.old_discount_amount;
      const taxRate = +item.room_rate_per_night > 7500 ? 18 : 12;
      return {
        BookingItemId: item.id,
        NumberOfNights: no_of_night,
        Rate: item.room_rate_per_night,
        TaxRate: taxRate,
        RoomDiscount: totalDiscountAmount,
        NetTotal:
          item.tax_type === "inclusive"
            ? grandTotalForInclusive(
                +item.room_rate_per_night,
                +no_of_night,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +totalDiscountAmount
              )
            : grandTotalForExclusive(
                +item.room_rate_per_night,
                +no_of_night,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +totalDiscountAmount
              ),
        TaxAmount:
          item.tax_type === "inclusive"
            ? taxAmountForInclusive(
                +item.room_rate_per_night,
                +no_of_night,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +totalDiscountAmount
              )
            : taxAmountForExclusive(
                +item.room_rate_per_night,
                +no_of_night,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +totalDiscountAmount
              ),
        TaxbleTotal:
          item.tax_type === "inclusive"
            ? calculateSubtotalForInclusive(
                +item.room_rate_per_night,
                +no_of_night,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +totalDiscountAmount
              )
            : calculateSubtotalForExclusive(
                +item.room_rate_per_night,
                +no_of_night,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +totalDiscountAmount
              ),
      };
    });

    const requestBody = {
      reservationId: bookingData.unique_booking_id,
      requestType: "extend_stay",
      CheckInDate: formatDate(earlyCheckInDate),
      CheckOutDate: bookingData.check_out,
      bookingNetTotal: +calculateTotal(rooms, "NetTotal") + +addOnCost.NetTotal,
      bookingTaxableAmount:
        +calculateTotal(rooms, "TaxbleTotal") + +addOnCost.taxableAmount,
      bookingTaxAmount:
        +calculateTotal(rooms, "TaxAmount") + +addOnCost.taxAmount,
      Room: rooms,
    };

    setAmendStayTotal(requestBody.bookingNetTotal);
    setShowCheckOutCalculations(true);
  };
  const handleEarlyCheckInSubmit = async (e) => {
    if (reasons.amendStayReason === "") {
      showErrorToast("Please enter the reason for late checkout");
      return;
    }

   

    const no_of_night = calculateNumberOfNights(
      new Date(earlyCheckInDate),
      new Date(bookingData?.check_out)
    );

    const rooms = amendStayData.map((item, index) => {
      const { childPrice, extraAdultPrice } = extractPrices(item.paxes);

      const totalDiscountAmount = item.old_discount_amount;
      const taxRate = +item.room_rate_per_night > 7500 ? 18 : 12;
      return {
        BookingItemId: item.booking_item_id,
        NumberOfNights: no_of_night,
        Rate: item.room_rate_per_night,
        TaxRate: taxRate,
        RoomID: item.room_id,
        RoomDiscount: totalDiscountAmount ? totalDiscountAmount : 0,
        NetTotal:
          item.tax_type === "inclusive"
            ? grandTotalForInclusive(
                +item.room_rate_per_night,
                +no_of_night,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +totalDiscountAmount
              )
            : grandTotalForExclusive(
                +item.room_rate_per_night,
                +no_of_night,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +totalDiscountAmount
              ),
        TaxAmount:
          item.tax_type === "inclusive"
            ? taxAmountForInclusive(
                +item.room_rate_per_night,
                +no_of_night,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +totalDiscountAmount
              )
            : taxAmountForExclusive(
                +item.room_rate_per_night,
                +no_of_night,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +totalDiscountAmount
              ),
        TaxbleTotal:
          item.tax_type === "inclusive"
            ? calculateSubtotalForInclusive(
                +item.room_rate_per_night,
                +no_of_night,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +totalDiscountAmount
              )
            : calculateSubtotalForExclusive(
                +item.room_rate_per_night,
                +no_of_night,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +totalDiscountAmount
              ),
      };
    });

    const requestBody = {
      reservationId: bookingData.unique_booking_id,
      requestType: "early_checkin",
      CheckInDate: formatDate(earlyCheckInDate),
      CheckOutDate: bookingData.check_out,
      bookingNetTotal: +calculateTotal(rooms, "NetTotal") + +addOnCost.NetTotal,
      bookingTaxableAmount:
        +calculateTotal(rooms, "TaxbleTotal") + +addOnCost.taxableAmount,
      bookingTaxAmount:
        +calculateTotal(rooms, "TaxAmount") + +addOnCost.taxAmount,
      bookingDueAmount:
        +(+calculateTotal(rooms, "NetTotal") + +addOnCost.NetTotal) -
        +bookingData.paid_amount,
      Room: rooms,
      requestReason: reasons.amendStayReason,
      bookingDiscountAmount: calculateTotal(rooms, "RoomDiscount"),
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
      body: JSON.stringify(requestBody),
    };

    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/reservation/modif/stay/${property_id.id}`,
        requestOptions
      );
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.description || data.error || "Unknown error");
      }

      showSuccessToast("Stay has been extended successfully");
      setAmendStayTotal(0);
      setShowCheckOutCalculations(false);
      handlePopupClose();
      fetchBookingDetails();
      setLateCheckOutDate("");
      setReasons({});
      getBookingData(bookingData.unique_booking_id);
      setLoader(false);

      return data;
    } catch (error) {
      showErrorToast(error.message || error.description || "Unknown error");
      setLoader(false);
      return null;
    }
  };

  const handleLateCheckInCalculate = (e) => {
    if (!lateCheckInDate) {
      showErrorToast("Please select a date for late checkout");
      return;
    }
    
    const no_of_night = calculateNumberOfNights(
      new Date(lateCheckInDate),
      new Date(bookingData?.check_out)
    );
    // const amendStayForRoom = amendStayDiscount / bookingData?.items?.length;

    const rooms = amendStayData.map((item, index) => {
      const { childPrice, extraAdultPrice } = extractPrices(item.paxes);
      console.log(childPrice, extraAdultPrice);
      // const discountedRatePerNight = +item.discount_amount / +item.no_nights;
      // const totalDiscountAmount = discountedRatePerNight * no_of_night;
      const totalDiscountAmount = item.old_discount_amount;
      const taxRate = +item.room_rate_per_night > 7500 ? 18 : 12;
      return {
        BookingItemId: item.booking_item_id,
        NumberOfNights: no_of_night,
        Rate: item.room_rate_per_night,
        TaxRate: taxRate,
        RoomDiscount: totalDiscountAmount,
        NetTotal:
          item.tax_type === "inclusive"
            ? grandTotalForInclusive(
                +item.room_rate_per_night,
                +no_of_night,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +totalDiscountAmount
              )
            : grandTotalForExclusive(
                +item.room_rate_per_night,
                +no_of_night,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +totalDiscountAmount
              ),
        TaxAmount:
          item.tax_type === "inclusive"
            ? taxAmountForInclusive(
                +item.room_rate_per_night,
                +no_of_night,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +totalDiscountAmount
              )
            : taxAmountForExclusive(
                +item.room_rate_per_night,
                +no_of_night,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +totalDiscountAmount
              ),
        TaxbleTotal:
          item.tax_type === "inclusive"
            ? calculateSubtotalForInclusive(
                +item.room_rate_per_night,
                +no_of_night,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +totalDiscountAmount
              )
            : calculateSubtotalForExclusive(
                +item.room_rate_per_night,
                +no_of_night,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +totalDiscountAmount
              ),
      };
    });

    const requestBody = {
      reservationId: bookingData.unique_booking_id,
      requestType: "extend_stay",
      CheckInDate: formatDate(lateCheckInDate),
      CheckOutDate: bookingData.check_out,
      bookingNetTotal: +calculateTotal(rooms, "NetTotal") + +addOnCost.NetTotal,
      bookingTaxableAmount:
        +calculateTotal(rooms, "TaxbleTotal") + +addOnCost.taxableAmount,
      bookingTaxAmount:
        +calculateTotal(rooms, "TaxAmount") + +addOnCost.taxAmount,
      Room: rooms,
    };

    setAmendStayTotal(requestBody.bookingNetTotal);
    setShowCheckOutCalculations(true);
  };
  const handleLateCheckInSubmit = async (e) => {
    if (reasons.amendStayReason === "") {
      showErrorToast("Please enter the reason for late checkout");
      return;
    }

    

    const no_of_night = calculateNumberOfNights(
      new Date(lateCheckInDate),
      new Date(bookingData?.check_out)
    );

    const rooms = amendStayData.map((item, index) => {
      const { childPrice, extraAdultPrice } = extractPrices(item.paxes);

      const totalDiscountAmount = item.old_discount_amount;
      const taxRate = +item.room_rate_per_night > 7500 ? 18 : 12;
      return {
        BookingItemId: item.booking_item_id,
        NumberOfNights: no_of_night,
        Rate: item.room_rate_per_night,
        TaxRate: taxRate,
        RoomID: item.room_id,
        RoomDiscount: totalDiscountAmount ? totalDiscountAmount : 0,
        NetTotal:
          item.tax_type === "inclusive"
            ? grandTotalForInclusive(
                +item.room_rate_per_night,
                +no_of_night,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +totalDiscountAmount
              )
            : grandTotalForExclusive(
                +item.room_rate_per_night,
                +no_of_night,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +totalDiscountAmount
              ),
        TaxAmount:
          item.tax_type === "inclusive"
            ? taxAmountForInclusive(
                +item.room_rate_per_night,
                +no_of_night,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +totalDiscountAmount
              )
            : taxAmountForExclusive(
                +item.room_rate_per_night,
                +no_of_night,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +totalDiscountAmount
              ),
        TaxbleTotal:
          item.tax_type === "inclusive"
            ? calculateSubtotalForInclusive(
                +item.room_rate_per_night,
                +no_of_night,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +totalDiscountAmount
              )
            : calculateSubtotalForExclusive(
                +item.room_rate_per_night,
                +no_of_night,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +totalDiscountAmount
              ),
      };
    });

    const requestBody = {
      reservationId: bookingData.unique_booking_id,
      requestType: "extend_checkin",
      CheckInDate: formatDate(lateCheckInDate),
      CheckOutDate: bookingData.check_out,
      bookingNetTotal: +calculateTotal(rooms, "NetTotal") + +addOnCost.NetTotal,
      bookingTaxableAmount:
        +calculateTotal(rooms, "TaxbleTotal") + +addOnCost.taxableAmount,
      bookingTaxAmount:
        +calculateTotal(rooms, "TaxAmount") + +addOnCost.taxAmount,
      bookingDueAmount:
        +(+calculateTotal(rooms, "NetTotal") + +addOnCost.NetTotal) -
        +bookingData.paid_amount,
      Room: rooms,
      requestReason: reasons.amendStayReason,
      bookingDiscountAmount: calculateTotal(rooms, "RoomDiscount"),
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
      body: JSON.stringify(requestBody),
    };

    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/reservation/modif/stay/${property_id.id}`,
        requestOptions
      );
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.description || data.error || "Unknown error");
      }

      showSuccessToast("Stay has been extended successfully");
      setAmendStayTotal(0);
      setShowCheckOutCalculations(false);
      handlePopupClose();
      fetchBookingDetails();
      setLateCheckOutDate("");
      setReasons({});
      getBookingData(bookingData.unique_booking_id);
      setLoader(false);

      return data;
    } catch (error) {
      showErrorToast(error.message || error.description || "Unknown error");
      setLoader(false);
      return null;
    }
  };

  const equalTableData = {
    width: "25%",
  };

  const handleAmendStayCancel = () => {
   
    setReasons((...prev) => ({
      ...prev,
      amendStayReason: "",
    }));
    setActiveIndex([]);
    setAmendStayTotal("");
    setLateCheckOutDate("");
    setEarlyCheckInDate("");
    setEarlyCheckInDate("");
    setLateCheckInDate("");
    setShowCheckOutCalculations(false);
    handlePopupClose();
  };

  return (
    <div>
      <div>
        <table
          className="modifyReservationPopupTable"
          style={{
            width: "100%",
            borderSpacing: "10px 5px",
          }}
        >
          <thead>
            <tr>
              <th style={equalTableData}>
                <label
                  className="flex flex_gap_5"
                  style={{ cursor: "pointer" }}
                >
                  <input
                    type="checkbox"
                    checked={selectedOption === "earlyCheckOut"}
                    onChange={() =>
                      handleAmendStaySelectCheckboxChange("earlyCheckOut")
                    }
                    style={{ marginRight: "5px" }}
                  />{" "}
                  <span> Early Check-out</span>
                </label>
              </th>
              <th style={equalTableData}>
                <label
                  className="flex flex_gap_5"
                  style={{ cursor: "pointer" }}
                >
                  <input
                    type="checkbox"
                    checked={selectedOption === "lateCheckOut"}
                    onChange={() =>
                      handleAmendStaySelectCheckboxChange("lateCheckOut")
                    }
                    style={{ marginRight: "5px" }}
                  />
                  <span> Extend Stay</span>
                </label>
              </th>
              <th style={equalTableData}>
                <label
                  className="flex flex_gap_5"
                  style={{ cursor: "pointer" }}
                >
                  <input
                    type="checkbox"
                    checked={selectedOption === "earlyCheckIn"}
                    onChange={() =>
                      handleAmendStaySelectCheckboxChange("earlyCheckIn")
                    }
                    style={{ marginRight: "5px" }}
                  />{" "}
                  <span> Early Check-In</span>
                </label>
              </th>
              <th style={equalTableData}>
                <label
                  className="flex flex_gap_5"
                  style={{ cursor: "pointer" }}
                >
                  <input
                    type="checkbox"
                    checked={selectedOption === "lateCheckIn"}
                    onChange={() =>
                      handleAmendStaySelectCheckboxChange("lateCheckIn")
                    }
                    style={{ marginRight: "5px" }}
                  />
                  <span> Late Check-In</span>
                </label>
              </th>
            </tr>
          </thead>
          <tbody>
            {selectedOption === "earlyCheckOut" && (
              <>
                <th className="flex flex_direction_col flex_gap_10">
                  <VerticalInputFieldCustom
                    titleName={"Check-Out Date"}
                    value={formatDateMMDDYYYY(new Date(bookingData.check_out))}
                    disabled={true}
                  />

                  <div>
                    Change to
                    <div className="Polaris-connected">
                      <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
                        <div className="Polaris-TextField">
                          <input
                            className={"Polaris-TextField__Input"}
                            type="date"
                            style={{ cursor: "pointer" }}
                            value={
                              earlyCheckOutDate
                                ? formatDate(new Date(earlyCheckOutDate))
                                : ""
                            }
                            onChange={handleEarlyCheckOutChange}
                            max={formatDate(new Date(bookingData.check_out))}
                            min={propertyCurrentDate}
                          />
                          <div className={"Polaris-TextField__Backdrop"}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </th>
                <th></th>
                <th></th>
                <th></th>
              </>
            )}
            {selectedOption === "lateCheckOut" && (
              <>
                <th></th>
                <th className="flex flex_direction_col flex_gap_10">
                  <VerticalInputFieldCustom
                    titleName={"Check-Out Date"}
                    value={formatDateMMDDYYYY(new Date(bookingData.check_out))}
                    disabled={true}
                  />

                  <div>
                    Change to
                    <div className="Polaris-connected">
                      <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
                        <div className="Polaris-TextField">
                          <input
                            className={"Polaris-TextField__Input"}
                            type="date"
                            style={{ cursor: "pointer" }}
                            value={
                              lateCheckOutDate
                                ? formatDate(lateCheckOutDate)
                                : ""
                            }
                            onChange={handleLateCheckOutChange}
                            min={formatDate(
                              new Date(
                                calculateToDatePlus(bookingData.check_out, 1)
                              )
                            )}
                          />
                          <div className={"Polaris-TextField__Backdrop"}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </th>
                <th></th>
                <th></th>
              </>
            )}
            {selectedOption === "earlyCheckIn" && (
              <>
                <th></th>
                <th></th>
                <th className="flex flex_direction_col flex_gap_10">
                  <VerticalInputFieldCustom
                    min={propertyCurrentDate}
                    titleName={"Check-In Date"}
                    value={formatDateMMDDYYYY(new Date(bookingData.check_in))}
                    disabled={true}
                  />

                  <div>
                    Change to
                    <div className="Polaris-connected">
                      <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
                        <div className="Polaris-TextField">
                          <input
                            className={"Polaris-TextField__Input"}
                            type="date"
                            min={propertyCurrentDate} 
                            style={{ cursor: "pointer" }}
                            value={
                              earlyCheckInDate
                                ? formatDate(earlyCheckInDate)
                                : ""
                            }
                            onChange={handleEarlyCheckInDateChange}
                            max={formatDate(new Date(bookingData.check_in))}
                            min={propertyCurrentDate}
                          />
                          <div className={"Polaris-TextField__Backdrop"}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </th>
                <th></th>
              </>
            )}
            {selectedOption === "lateCheckIn" && (
              <>
                <th></th>
                <th></th>
                <th></th>
                <th className="flex flex_direction_col flex_gap_10">
                  <VerticalInputFieldCustom
                    titleName={"Check-In Date"}
                    value={formatDateMMDDYYYY(new Date(bookingData.check_in))}
                    disabled={true}
                  />

                  <div>
                    Change to
                    <div className="Polaris-connected">
                      <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
                        <div className="Polaris-TextField">
                          <input
                            className={"Polaris-TextField__Input"}
                            type="date"
                            style={{ cursor: "pointer" }}
                            value={
                              lateCheckInDate ? formatDate(lateCheckInDate) : ""
                            }
                            onChange={handleLateCheckInDateChange}
                            min={formatDate(new Date(bookingData.check_in))}
                            max={formatDate(new Date(bookingData.check_out))}
                          />
                          <div className={"Polaris-TextField__Backdrop"}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </th>
              </>
            )}
          </tbody>
        </table>
        <hr style={{ margin: "10px 0" }} />
        <table className="modifyReservationPopupTable" style={{ width: "70%" }}>
          <thead>
            <tr>
              <th />
              <th>Room Category</th>
              <th>Rate/Night</th>
              <th>Disc. Amt</th>
            </tr>
          </thead>
          <tbody>
            {amendStayData?.map((item, roomIndex) => {
              return (
                <tr key={roomIndex}>
                  <td>
                    <input
                      type="checkbox"
                      value={""}
                      onChange={(e) => {
                        handleAmendStayCheckboxChange(e, roomIndex);
                      }}
                      checked={activeIndex.includes(roomIndex)}
                    />
                  </td>
                  <td>
                    <VerticalInputFieldCustom
                      value={item?.room_category}
                      disabled={true}
                    />
                  </td>
                  <td>
                    <VerticalInputFieldCustom
                      value={item?.room_rate_per_night}
                      type={"number"}
                      name="room_rate_per_night"
                      onChange={(e) => {
                        handleAmendStayRateChange(e, roomIndex);
                      }}
                      disabled={!activeIndex.includes(roomIndex)}
                    />
                  </td>
                  <td>
                    <VerticalInputFieldCustom
                      type={"number"}
                      value={item?.old_discount_amount}
                      name={"old_discount_amount"}
                      onChange={(e) => {
                        handleAmendStayDiscountChangeSingle(e, roomIndex);
                      }}
                      disabled={!activeIndex.includes(roomIndex)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="modify_reservation_reason_container">
          <VerticalInputFieldCustom
            titleName={"Reason to Modify*"}
            name="amendStayReason"
            onChange={handleReasonChange}
          />

          <div className="modify_reservation_reason_container flex flex_gap_10 align_items_center">
            <label>New Total:</label>
            <VerticalInputFieldCustom disabled={true} value={amendStayTotal} />

            <label>Previous Total:</label>
            <p>{bookingData.grand_total}</p>
          </div>
        </div>

        <div className="flex flex_gap_10">
          {selectedOption === "earlyCheckOut" && (
            <>
              <BgThemeButton
                children={!showCheckOutCalculations ? "Calculate" : "Modify"}
                loader={loader}
                onClick={
                  !showCheckOutCalculations
                    ? handleEarlyCheckOutCalculate
                    : handleEarlyCheckOutSubmit
                }
              />
            </>
          )}
          {selectedOption === "lateCheckOut" && (
            <>
              <BgThemeButton
                children={!showCheckOutCalculations ? "Calculate" : "Modify"}
                loader={loader}
                onClick={
                  !showCheckOutCalculations
                    ? handleLateCheckOutCalculate
                    : handleLateCheckOutSubmit
                }
              />
            </>
          )}
          {selectedOption === "earlyCheckIn" && (
            <>
              <BgThemeButton
                children={!showCheckOutCalculations ? "Calculate" : "Modify"}
                loader={loader}
                onClick={
                  !showCheckOutCalculations
                    ? handleEarlyCheckInCalculate
                    : handleEarlyCheckInSubmit
                }
              />
            </>
          )}

          {selectedOption === "lateCheckIn" && (
            <>
              <BgThemeButton
                children={!showCheckOutCalculations ? "Calculate" : "Modify"}
                loader={loader}
                onClick={
                  !showCheckOutCalculations
                    ? handleLateCheckInCalculate
                    : handleLateCheckInSubmit
                }
              />
            </>
          )}

          <BgOutlineThemeButton
            children="Cancel"
            onClick={handleAmendStayCancel}
          />
        </div>
      </div>
    </div>
  );
};

export default ModifyReservationConfirmAmendStay;
