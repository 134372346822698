import React from "react";

const UnderscoreRemoveCapitalize = ({ text, textColor, fontWeight, fontSize , width ,onClick}) => {
  return (
    <>
      {
        <div onClick={onClick}  style={{ textTransform: "capitalize", color: textColor, fontWeight: fontWeight, fontSize: fontSize, width: width , cursor: onClick && "pointer" }}>
          {text && text?.replace(/_/g, " ")} 
        </div>
      }
    </>
  );
};

export default UnderscoreRemoveCapitalize;
