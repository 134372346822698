import { Icon, IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import Loader from "../UI/Loader/Loader";
import BookingStatusUpdate from "../CustomComponents/BookingStatusUpdate";
import DownloadPdfTables from "../CustomComponents/DownloadPdfTables/DownloadPdfTables";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "../CustomComponents/CustomReportComponent/CustomReportComponent";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";
import { useState } from "react";
import InformationCustomPopup from "../InformationCustomPopup/InformationCustomPopup";
import AccordionComponent from "../NewCustomComp/accordionComponent/AccordionComponent";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";
import { CancelMajor } from "@shopify/polaris-icons";
import { showErrorToast } from "../../assets/toastUtils";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { formatIndianCurrency } from "../../utils/utils";

const ReportPaymentInvoice = ({
  departureReport,
  loader,
  settlementOrders,
  getSingleBookingDataHandler,
  property_id,
  userToken,
}) => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };
  const names = [
    {
      bill_created: "Bill Created",
      booking_id: "Booking ID",
      bill_no: "Bill No",
      booked_on: "Booked On",
      guest_name: "Guest Name",
      check_in: "Check In",
      check_out: "Check Out",
      booking_source: "Booking Source",
      status: "Status",
    },
  ];

  const [informationPopup, setInformationPopup] = useState(false);

  //   bill_created
  // :
  // "01 Jan, 1970"
  // bill_no
  // :
  // null
  // booked_on
  // :
  // "02 Feb, 2024"
  // booking_id
  // :
  // 304723
  // booking_source
  // :
  // "walk_in_bookings"
  // check_in
  // :
  // "2024-02-05"
  // check_out
  // :
  // "2024-02-07"
  // guest_name
  // :
  // null
  // status
  // :
  // null

  const rowMarkup =
    departureReport &&
    departureReport.length > 0 &&
    departureReport?.map((order) => (
      <CustomReportTableRow id={order.id} key={order.id} position={order.index}>
        <CustomReportTableCell>{order.bill_created }</CustomReportTableCell>
        <CustomReportTableCell>
          {order.bill_no ? (
            <span
              className="link"
              onClick={() => handleSubmitPreview(order?.folio_id)}
            >
              {order.bill_no}
            </span>
          ) : (
            "-"
          )}
        </CustomReportTableCell>
        <CustomReportTableCell>
          <span
            className="link"   
            onClick={() => getSingleBookingDataHandler(order.unique_booking_id)}
          >
            #{order.unique_booking_id || "-"}
          </span>
        </CustomReportTableCell>

        <CustomReportTableCell>
          {order.guest_name ? order.guest_name : "-"}
        </CustomReportTableCell>

        <CustomReportTableCell>
          {order.booked_on ? order.booked_on : "-"}
        </CustomReportTableCell>
       
        <CustomReportTableCell>
          {order.check_in ? order.check_in : "-"}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {order.check_out ? order.check_out : "-"}
        </CustomReportTableCell>
        <CustomReportTableCell>
          <UnderscoreRemoveCapitalize
            text={order.booking_source ? order.booking_source : "-"}
          />
        </CustomReportTableCell>
        <CustomReportTableCell>
          {order.invoice_amount ? formatIndianCurrency(order.invoice_amount) : "-"}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {order.paid_amount ? formatIndianCurrency(order.paid_amount) : "-"}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {order.balance_amount ?formatIndianCurrency(order.balance_amount) : "-"}
        </CustomReportTableCell>



        <CustomReportTableCell>
          <BookingStatusUpdate
            status={order?.payment_status}
          />
          {/* {order.payment_status ? order?.payment_status : "-"} */}
        </CustomReportTableCell>
      </CustomReportTableRow>
    ));


  const [iframeSrc, setIframeSrc] = useState("");
  const [showIframePopup, setShowIframePopup] = useState(false);
  const [showBillPreview, setShowBillPreview] = useState(false);
  const [iframeContent, setIframeContent] = useState("");
  const [downloadUrl, setDownloadUrl] = useState("");

  const handleSubmitPreview = async (booking_id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/layout/preview/${property_id?.id}/bill?previewType=roomBillPreview&previewId=${booking_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const iframeContent = await response.text(); // API response as text (iframe content)

      // Save iframe content and control states
      setIframeSrc(iframeContent);
      setIframeContent(iframeContent);
      setShowIframePopup(true);
      setShowBillPreview(true);
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const printIframe = (id) => {
    // setIsLoading(false);
    const iframe = document.frames
      ? document.frames[id]
      : document.getElementById(id);
    const iframeWindow = iframe.contentWindow || iframe;

    iframe.focus();
    iframeWindow.print();

    return false;
  };

  const handleDownload = () => {
    const iframe = document.getElementById("receipt");
    const iframeDocument =
      iframe.contentDocument || iframe.contentWindow.document;

    // Get the dimensions of the iframe
    const iframeWidth = iframe.offsetWidth;
    const iframeHeight = iframe.offsetHeight;

    // Use html2canvas to render the iframe content as an image
    html2canvas(iframeDocument.body, {
      scale: 0.5, // Increase scale for better image quality
      width: iframeWidth,
      height: iframeHeight,
      logging: false,
      useCORS: true,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      // Create a new jsPDF instance
      const pdf = new jsPDF("p", "in", "letter");

      // Get the page size (letter size is 8.5x11 inches)
      const pageWidth = 8.5; // Width in inches
      const pageHeight = 11; // Height in inches

      // Scale the image to fit within the page dimensions
      const imgWidth = pageWidth * 72; // Convert inches to points (1 inch = 72 points)
      const imgHeight = (iframeHeight * imgWidth) / iframeWidth; // Scale proportionally

      // If the image height is too large, scale it down to fit within the page height
      if (imgHeight > pageHeight * 72) {
        const scaleFactor = (pageHeight * 72) / imgHeight;
        imgWidth *= scaleFactor;
        imgHeight *= scaleFactor;
      }

      // Add the image to the PDF (adjust coordinates to center it on the page)
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth / 72, imgHeight / 72); // Convert from points to inches

      // Save the PDF with a name
      pdf.save("receipt.pdf");
    });
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        // <div style={{ display: "flex" }}>

        <div
          className="room-types-full-container reports-new-background"
          id="receipt"
        >
          <div className="room-types-full-container-div">
            <div className="flex flex_gap_5 " style={{ alignItems: "unset" }}>
              <div className="room-type-heading">Invoice Report</div>
              <div
                className="hoverCursor"
                onClick={() => setInformationPopup(true)}
                id="doNotPrint"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 -960 960 960"
                  width="24px"
                  fill="#3968ED"
                >
                  <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
                </svg>
              </div>
            </div>
            {departureReport?.length > 0 && (
              <div className="flex flex_gap_15" id="doNotPrint">
                {/* <DownloadPdfTables
                  tableData={departureReport}
                  tableNames={names}
                  downloadFileName="invoice_report"
                /> */}

                <ButtonToDownloadTablePDF
                  itemToDownloadID={"receipt"}
                  downloadFileName={"invoice_report"}
                  ignoreFromPrint={"doNotPrint"}
                  longPdf={true}
                  pageFormat={"a3"}
                />
                <DownloadCSVButton
                  downloadFileName="invoice_report"
                  itemToDownloadID={"receipt"}
                />
              </div>
            )}
          </div>

          {departureReport && departureReport.length > 0 ? (
            <>
              
              <CustomReportTable
                headings={[
                  "Invoice Date",
                  "Invoice No",
                  "Booking ID",
                  "Guest Name",
                  "Booking Date",
                  "Check In",
                  "Check Out",
                  "Booking Source",
                  "Invoice Amount",
                  "Paid Amount",
                  "Balance",
                  "Payment Status",
                ]}
                headerStyles={{
                  backgroundColor: "#dddddd",
                  fontSize: "12px",
                  
                }}
                tableStyles={{ textAlign: "left" }}
              >
                {rowMarkup}
                
              </CustomReportTable>
            </>
          ) : (
            <NoDataFound />
          )}
        </div>

        // </div>
      )}

      {informationPopup && (
        <InformationCustomPopup
          setInformationPopup={setInformationPopup}
          title="Information of Invoice Report"
        >
          <div className="mb-20">
            <AccordionComponent
              questions={[
                {
                  question: "What is the Invoice Report?",
                  answer: (
                    <p>
                      The <b>Invoice Report</b> provides a comprehensive
                      breakdown of invoices generated for guest bookings,
                      filtered by booking date or check-in date. This report
                      captures essential information such as the booking source,
                      invoice status, payment status, and balance details. It is
                      particularly useful for tracking and managing guest
                      payments and outstanding balances across various booking
                      channels.
                    </p>
                  ),
                },
                {
                  question: "What is the Importance of the Invoice Report?",
                  answer: (
                    <div>
                      <p>The Invoice Report is crucial for:</p>

                      <ul>
                        <li>
                          <b>Payment Tracking: </b>Helps monitor payments made,
                          pending amounts, and payment statuses (e.g., paid,
                          partially paid, unpaid).
                        </li>
                        <li>
                          <b>Financial Oversight: </b>Ensures that all invoices
                          are generated, and discrepancies such as unpaid or
                          partially paid bookings are addressed.
                        </li>
                        <li>
                          <b>Operational Transparency: </b>Provides a clear view
                          of the financial standing of each booking, including
                          no-shows and cancellations.
                        </li>
                        <li>
                          <b>Channel Performance: </b>Allows for analysis of the
                          performance of different booking sources (e.g., OTA,
                          website, agents) and the related invoice settlements.
                        </li>
                      </ul>
                      <p>
                        This report is key for ensuring smooth financial
                        operations, improving payment collection, and managing
                        outstanding balances.
                      </p>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </InformationCustomPopup>
      )}

      {showIframePopup && (
        <>
          <div className="bg-backdrop">
            <section className="communication_iframe_container">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "10px",
                  padding: "10px",
                }}
              >
                <div
                  className="add-ota-model-container-heading"
                  style={{ display: "flex", gap: "10px" }}
                >
                  Tax Invoice
                  <div
                    onClick={() => printIframe("receipt")}
                    style={{ cursor: "pointer" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="12"
                      viewBox="0 0 14 12"
                      fill="none"
                    >
                      <path
                        d="M11.0007 2.66667H3.00065V0H11.0007V2.66667ZM11.0007 6.33333C11.1895 6.33333 11.348 6.26933 11.476 6.14133C11.604 6.01333 11.6678 5.85511 11.6673 5.66667C11.6673 5.47778 11.6033 5.31933 11.4753 5.19133C11.3473 5.06333 11.1891 4.99956 11.0007 5C10.8118 5 10.6533 5.064 10.5253 5.192C10.3973 5.32 10.3335 5.47822 10.334 5.66667C10.334 5.85556 10.398 6.014 10.526 6.142C10.654 6.27 10.8122 6.33378 11.0007 6.33333ZM9.66732 10.6667V8H4.33398V10.6667H9.66732ZM11.0007 12H3.00065V9.33333H0.333984V5.33333C0.333984 4.76667 0.528429 4.29156 0.917318 3.908C1.30621 3.52444 1.77843 3.33289 2.33398 3.33333H11.6673C12.234 3.33333 12.7091 3.52511 13.0927 3.90867C13.4762 4.29222 13.6678 4.76711 13.6673 5.33333V9.33333H11.0007V12Z"
                        fill="#3968ED"
                      />
                    </svg>
                  </div>
                  <div onClick={handleDownload} style={{ cursor: "pointer" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <path
                        d="M6.99957 10.1107L3.11068 6.22179L4.19957 5.09401L6.22179 7.11623V0.777344H7.77734V7.11623L9.79957 5.09401L10.8885 6.22179L6.99957 10.1107ZM2.3329 13.2218C1.90512 13.2218 1.53879 13.0693 1.2339 12.7645C0.929012 12.4596 0.776827 12.0935 0.777345 11.6662V9.3329H2.3329V11.6662H11.6662V9.3329H13.2218V11.6662C13.2218 12.094 13.0693 12.4603 12.7645 12.7652C12.4596 13.0701 12.0935 13.2223 11.6662 13.2218H2.3329Z"
                        fill="#3968ED"
                      />
                    </svg>
                  </div>
                  {downloadUrl && (
                    <a href={downloadUrl} download="receipt.html">
                      Download
                    </a>
                  )}
                </div>
                <div
                  onClick={() => setShowIframePopup(false)}
                  style={{ cursor: "pointer" }}
                >
                  <Icon source={CancelMajor} color="base" />
                </div>
              </div>
              <div style={{ background: "#ddddd", height: "100%" }}>
                <iframe srcDoc={iframeContent} title="Receipt" id="receipt" />
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );
};
export default ReportPaymentInvoice;
