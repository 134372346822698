import React from "react";
import HoldRoomCreateAgent from "../Components/CustomComponents/HoldRoomCreateAgent";

const HoldRoomsCreateAgentPage = () => {
  return (
    <div>
      <HoldRoomCreateAgent />
    </div>
  );
};

export default HoldRoomsCreateAgentPage;
