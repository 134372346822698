export function calculateTotal(array, property) {
  return array?.reduce((total, item) => {
    const value = parseFloat(item[property]) || 0;
    return total + value;
  }, 0);
}

export function calculateTotalAddons(array, property) {
    if (!Array.isArray(array)) {
      return 0;
    }
    return array?.reduce((total, item) => {
      if (item.is_cancelled === 0) {
        const value = parseFloat(item[property]) || 0;
        return total + value;
      }
      return total;
    }, 0);
  }

  export function calculateNumberOfNights(checkInDate, checkOutDate) {

    const checkInTime = checkInDate?.getTime();
    const checkOutTime = checkOutDate?.getTime();
    const differenceMs = checkOutTime - checkInTime;
    const differenceDays = differenceMs / (1000 * 3600 * 24);
    const numberOfNights = Math.floor(differenceDays);

    return numberOfNights;
  }

