import { useSelector } from "react-redux";
import { showErrorToast, showSuccessToast } from "../assets/toastUtils";
import { useEffect, useState } from "react";

export const formatDateYYYYMMDD = (date) => {
  // alert("called")
  const dateObj = new Date(date);
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(dateObj.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

// to calculate the date plus function
export const calculateToDatePlus = (fromDate, days) => {
  const toDate = new Date(fromDate);
  toDate.setDate(toDate.getDate() + days);
  return toDate.toISOString().split("T")[0];
};

//
export const calculateToDateMinus = (fromDate, days) => {
  const toDate = new Date(fromDate);
  toDate.setDate(toDate.getDate() - days);
  return toDate?.toISOString().split("T")[0];
};

// export function formatIndianCurrency(amount) {
//   const currencyFormatter = new Intl.NumberFormat("en-IN", {
//     style: "currency",
//     currency: "INR",
//   });
//   const formattedAmount = currencyFormatter.format(amount);
//   return `${
//     isNaN(formattedAmount) ?formattedAmount  : currencyFormatter.format(0)
//   }`;
// }
export function formatIndianCurrency(amount) {
  const currencyFormatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });

  // Check if amount is NaN and set it to zero if true
  amount = isNaN(amount) ? 0 : amount;

  const formattedAmount = currencyFormatter.format(amount);
  return formattedAmount;
}

export const formatAMPM = (date) => {
  if (!date) return "";

  let hours = date.getUTCHours();
  let minutes = date.getUTCMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  const strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

export const formatDate = (date) => {
  if (!date) return "";

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const day = date.getUTCDate();
  const monthIndex = date.getUTCMonth();
  const year = date.getUTCFullYear();
  return `${monthNames[monthIndex]} ${day}, ${year}`;
};

export function getTimeFromTimestamp(timestamp) {
  const date = new Date(timestamp);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
  return `${formattedHours}:${formattedMinutes} ${ampm}`;
}

export const checkFolioSummery = (
  userToken,
  folioId,
  property_id,
  data,
  showMessage
) => {
  try {
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/sync/folios/summary/${folioId}/${property_id}`,
      {
        method: "POST",
        body: JSON.stringify({
          grand_total: data?.grandTotalSum,
          paid_amount: data?.paidAmountSum,
          sub_total: data?.subTotalSum,
          discount_amount: data?.discountAmountSum,
          tax_amount: data?.taxAmountSum,
          balance_amount: data?.grandTotalSum - data?.paidAmountSum,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showMessage && showSuccessToast(data.message);
        } else {
          showErrorToast(data.message);
        }
      });
  } catch (error) {}
};

export const getCurrentPropertyDate = (property_id) => {
  if (
    property_id &&
    property_id?.night_audit_logs &&
    property_id?.night_audit_logs?.length > 0
  ) {
    const nightAuditDate = property_id.night_audit_logs[0].day_start_date;
    if (nightAuditDate) {
      return nightAuditDate;
    } else {
      return new Date()?.toISOString()?.split("T")[0];
    }
  } else {
    return new Date()?.toISOString()?.split("T")[0];
  }
};

export function convertTo12HourFormat(time24) {
  if (!time24) {
    return null;
  }

  const [hours24, minutes, seconds] = time24.split(":").map(Number);

  const meridian = hours24 >= 12 ? "PM" : "AM";

  let hours12 = hours24 % 12;
  hours12 = hours12 === 0 ? 12 : hours12;

  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  const time12 = `${hours12}:${formattedMinutes} ${meridian}`;

  return time12;
}

// function to get the current propertyDate
export const getCurrentPropertyDateHandler = async (propertyId, userToken) => {
  if (!userToken) return;
  if (getActiveApp() !== "pms") return;

  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/get/current/date/${propertyId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error("Failed to fetch current date from the API");
    }

    const data = await response.json();
    if (data.success) {
      // localStorage.setItem(
      //   "activePropertyDate",
      //   JSON.stringify(data.latest_day_start_date)
      // );
      return data.latest_day_start_date;
    }
  } catch (error) {
    console.error(error);
    return "Error fetching current date";
  }
};

export const currentDateSingle = new Date().toISOString().split("T")[0];

export const useCurrentTime = (timeFormat) => {
  const [currentTime, setCurrentTime] = useState(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const getCurrentTime = () => {
        let date = new Date();
        let hours = date.getHours();
        let minutes = date.getMinutes();

        if (timeFormat === "12") {
          let ampm = hours >= 12 ? "PM" : "AM";
          hours = hours % 12;
          hours = hours ? hours : 12; // the hour '0' should be '12'
          minutes = minutes < 10 ? "0" + minutes : minutes;
          return hours + ":" + minutes + " " + ampm;
        } else {
          hours = hours < 10 ? "0" + hours : hours;
          minutes = minutes < 10 ? "0" + minutes : minutes;
          return hours + ":" + minutes;
        }
      };

      setCurrentTime(getCurrentTime());
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeFormat]);

  return currentTime;
};

export const globalErrorHandler = (data, keys) => {
  let errors = [];

  const isArray = Array.isArray(data);

  if (isArray) {
    data.forEach((obj, index) => {
      let objErrors = {};
      keys.forEach((key) => {
        if (!obj[key]) {
          objErrors[key] = `Please enter the ${key}`;
        }
      });
      // if (Object.keys(objErrors).length > 0) {
      errors.push(objErrors);
      // }
    });
  } else {
    let objErrors = {};
    keys.forEach((key) => {
      if (!data[key]) {
        objErrors[key] = `Please enter the ${key}`;
      }
    });
    if (Object.keys(objErrors).length > 0) {
      errors = objErrors;
    }
  }

  return isArray
    ? errors.length > 0
      ? { errors, success: true }
      : { success: false, errors }
    : Object.keys(errors).length > 0
    ? { errors, success: true }
    : { success: false, errors };
  return errors;
};

export function calculateTotalWithReduce(array, property ,noDecimal) {
  // Check if the input is an array
  if (!Array.isArray(array)) {
    throw new TypeError("First argument must be an array");
  }

  // Check if the property is a string
  if (typeof property !== "string") {
    throw new TypeError("Second argument must be a string");
  }

  const total = array.reduce((sum, item) => {
    // Convert the property value to a number, default to 0 if NaN
    const value = parseFloat(item[property]) || 0;
    return sum + value;
  }, 0);

  // Return the total with two decimal places
  return noDecimal ? total.toFixed(0) : total.toFixed(2);
}

export function calculateTotalArrayObjectKey(array, property) {
  if (!Array.isArray(array)) {
    return 0;
  }

  const getProperty = (obj, path) => {
    if (!obj || typeof obj !== 'object' || !path) {
      return 0;
    }

    const keys = path.split('.'); // Split property if it's a string path
    let value = obj;

    for (const key of keys) {
      if (value && typeof value === 'object' && key in value) {
        value = value[key];
      } else {
        return 0; // Return 0 if any part of the path is invalid
      }
    }
    
    return value;
  };

  return array.reduce((total, item) => {
    const value = parseFloat(getProperty(item, property));
    return total + (isNaN(value) ? 0 : value); // Ensure that NaN values are treated as 0
  }, 0);
}




export const getBaseUrl = () => {
  let activeApp = localStorage.getItem("activeApp");

  if (!activeApp) {
    const hostname = window.location.hostname;
    if (hostname.includes("booking-engine")) {
      activeApp = "booking_engine";
    } else if (hostname.includes("channel-manager")) {
      activeApp = "channel_manager";
    } else {
      activeApp = "pms";
    }
  }

  switch (activeApp) {
    case "pms":
      return process.env.REACT_APP_BASE_URL;
    case "booking_engine":
      return process.env.REACT_APP_BASE_URL_BOOKING_ENGINE;
    case "channel_manager":
      return process.env.REACT_APP_BASE_URL_CHANNEL_MANAGER;
    default:
      return process.env.REACT_APP_DEFAULT_BASE_URL;
  }
};

export const getActiveApp = () => {
  let activeApp = localStorage.getItem("activeApp");

  if (!activeApp) {
    const hostname = window.location.hostname;
    if (hostname.includes("booking-engine")) {
      activeApp = "booking_engine";
    } else if (hostname.includes("channel-manager")) {
      activeApp = "channel_manager";
    } else {
      activeApp = "pms";
    }
  }

  return activeApp;
};

export const validateFormFields = (formData, requiredFields) => {
  // required fields  is array of the key names
  const errors = {};

  requiredFields.forEach((field) => {
    if (!formData[field] || formData[field].trim() === "") {
      errors[field] = `${field} is required`;
    }
  });

  return errors;
};

export const getDateByMonth = (dateStr) => {
  const date = new Date(dateStr);

  // Get day, month, and year in the required format
  const day = date.toLocaleDateString("en-GB", { day: "2-digit" });
  const month = date.toLocaleDateString("en-GB", { month: "short" });
  const year = date
    .toLocaleDateString("en-GB", { year: "numeric" })
    .toUpperCase();

  return `${day} ${month}, ${year}`;
};

export function getTimeFromTimeTwelveHoursFormat(timeString) {
  const [hours, minutes, seconds] = timeString.split(":").map(Number);
  const ampm = +hours >= 12 ? "PM" : "AM";
  const date = new Date();
  date.setHours(hours, minutes, seconds);
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
  return `${formattedHours}:${formattedMinutes} ${ampm}`;
}

export function calculateDaysAndNights(startDate, endDate) {
  // Parse the input dates into Date objects
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Set both dates to midnight to remove the time factor
  start.setHours(0, 0, 0, 0);
  end.setHours(0, 0, 0, 0);

  // Calculate the difference in time (milliseconds)
  const diffTime = end - start;

  // Convert the time difference into days
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // milliseconds to days

  // Number of nights is one less than the number of days
  const nights = diffDays - 1;

  return {
    days: diffDays,
    nights: nights > 0 ? nights : 0, // Ensure nights don't go negative
  };
}

export const getDateAndMonth = (dateStr) => {
  const date = new Date(dateStr);

  // Get day, month, and year in the required format
  const day = date.toLocaleDateString("en-GB", { day: "2-digit" });
  const month = date.toLocaleDateString("en-GB", { month: "short" });
  const year = date
    .toLocaleDateString("en-GB", { year: "numeric" })
    .toUpperCase();

  return `${day} ${month}`;
};

export function extractImageName(url) {
  if (!url) return;
  const urlParts = url.split("/");
  return urlParts[urlParts.length - 1];
}

export const findEmptyKeysInArray = (dataArray, keysArray) => {
  return dataArray
    .map((item, index) => {
      let emptyKeys = {};
      keysArray[index]?.forEach((key) => {
        if (!item[key] && item[key] !== 0) {
          emptyKeys[key] = item[key];
        }
      });

      return emptyKeys;
    })
    .filter((emptyKeysObj) => Object.keys(emptyKeysObj).length > 0); // Only return objects with empty keys
};

export const capitalizeFirstLetter = (str) => {
  return str
    ?.toLowerCase() // Ensure all letters are lowercase first
    ?.replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter of each word
};

export function removeKeysFromLocalStorage(keys) {
  if (!Array.isArray(keys) || keys.length === 0) return;

  keys.forEach((key) => localStorage.removeItem(key));
}

export const hasPermission = (requiredPermissions) => {
  const allPermissions = JSON.parse(localStorage.getItem("permissions")) || [];
  if (Array.isArray(requiredPermissions)) {
    return requiredPermissions.every((permission) =>
      allPermissions.includes(permission)
    );
  }
  return allPermissions.includes(requiredPermissions);
};


// sort report data 

export function sortReportData (data, sortOrder) {
  return [...data].sort((a, b) => {
    switch (sortOrder) {
      case "booking_count_high_to_low":
        return b.booking_count - a.booking_count;
      case "booking_count_low_to_high":
        return a.booking_count - b.booking_count;
      case "booking_amount_high_to_low":
        return b.total_sale - a.total_sale;
      case "booking_amount_low_to_high":
        return a.total_sale - b.total_sale;
      default:
        return 0; // No sorting if "select" or invalid value is passed
    }
  });
}

export function calculateNestedTotal(dataArray, nestedArrayKey, targetKey) {
  let total = 0;
  
  dataArray.forEach(item => {
      if (Array.isArray(item[nestedArrayKey])) {
          item[nestedArrayKey].forEach(nestedItem => {
              if (nestedItem[targetKey] !== undefined && typeof nestedItem[targetKey] === 'number') {
                  total += nestedItem[targetKey];
              }
          });
      }
  });
  
  return total;
}


// Round Up/ Down
export function performRounding(totalAmount, roundType) {
  const roundedData = {
    roundedAmount: totalAmount ? totalAmount : 0,
    roundingDifference: 0,
  };

  if (roundType === "roundUp") {
    roundedData.roundedAmount = Math.ceil(totalAmount); // Round up
    roundedData.roundingDifference = +(
      roundedData.roundedAmount - totalAmount
    ).toFixed(2);
  } else if (roundType === "roundDown") {
    roundedData.roundedAmount = Math.floor(totalAmount); // Round down
    roundedData.roundingDifference = +(
      roundedData.roundedAmount - totalAmount
    ).toFixed(2);
  } else if (!roundType) {
    // Default case when roundType is not provided
    roundedData.roundedAmount = totalAmount;
    roundedData.roundingDifference = 0;
  } else {
    throw new Error("Invalid roundType. Please use 'roundUp' or 'roundDown'.");
  }

  return roundedData;
}