import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import DownloadPdfTables from "../CustomComponents/DownloadPdfTables/DownloadPdfTables";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "../CustomComponents/CustomReportComponent/CustomReportComponent";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";
import InformationCustomPopup from "../InformationCustomPopup/InformationCustomPopup";
import AccordionComponent from "../NewCustomComp/accordionComponent/AccordionComponent";
import { useState } from "react";
import {
  calculateTotalWithReduce,
  formatIndianCurrency,
} from "../../utils/utils";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";
import { SvgGetInfo } from "../../assets/svgIcons";
const ReportNoShowTable = ({
  departureReport,
  getSingleBookingDataHandler,
}) => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };
  const extractedData = departureReport?.map((item) => ({
    unique_booking_id:
      item && item?.unique_booking_id ? item?.unique_booking_id : "N/A",
    guest_name:
      item && item?.guest[0] && item?.guest[0]?.guest_name
        ? item?.guest[0]?.guest_name
        : "N/A",
    check_in: item && item?.check_in ? item?.check_in : "N/A",
    check_out: item && item?.check_out ? item?.check_out : "N/A",
    room_type_name:
      item && item?.items[0] && item?.items[0]?.room_type_name
        ? item?.items[0]?.room_type_name
        : "N/A",
    booking_source:
      item && item?.booking_source
        ? item?.booking_source?.replace(/_/g, " ")
        : "N/A",
    status: item && item?.status ? item?.status?.replace(/_/g, " ") : "N/A",
  }));
  const [informationPopup, setInformationPopup] = useState(false);

  const names = [
    {
      unique_booking_id: "Booking ID",
      guest_name: "Name",
      updated_at: "",
      check_in: "Arrival Date",
      check_out: "Departure Date",
      room_type_name: "Room Category",
      booking_source: "Booking Source",
      status: "Status",
    },
  ];

  //   Reservation/Confirmation Number d
  // Guest Name d
  // Arrival Date d
  // Departure Date d
  // Room Type Booked d
  // Reservation Source d
  // Source Reservation ID (for OTA bookings) d

  const rowMarkup =
    departureReport &&
    departureReport.length > 0 &&
    departureReport?.map((order) => (
      <CustomReportTableRow id={order.id} key={order.id} position={order.index}>
        <CustomReportTableCell>
          {" "}
          <div
            className="pointer"
            style={{
              color: "rgba(57, 104, 237, 1)",
            }}
            onClick={() => getSingleBookingDataHandler(order.unique_booking_id)}
          >
            #{order.unique_booking_id || ""}
          </div>
        </CustomReportTableCell>
        <CustomReportTableCell>
          {order?.guest[0]?.guest_name}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {order?.booking_date ? (
            <TextDateFormatter
              as={"span"}
              showYear={true}
              date={order.booking_date}
            />
          ) : (
            <>-</>
          )}
        </CustomReportTableCell>
        <CustomReportTableCell>
          <TextDateFormatter
            as={"span"}
            showYear={true}
            date={order.check_in}
          />
        </CustomReportTableCell>

        <CustomReportTableCell>
          <TextDateFormatter
            as={"span"}
            showYear={true}
            date={order.check_out}
          />
        </CustomReportTableCell>
        <CustomReportTableCell>
          {order?.items.map((item, index) => (
            <span key={index}>
              {item.room_type_name} {index < order?.items.length - 1 ? "," : ""}{" "}
              &nbsp;
            </span>
          ))}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {order.items.length > 0
            ? calculateTotalWithReduce(order.items, "no_nights", "noDecimals")
            : "-"}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {order.items.length > 0
            ? +calculateTotalWithReduce(order.items, "no_adult", "noDecimals") +
              +calculateTotalWithReduce(order.items, "no_child", "noDecimals")
            : "-"}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {formatIndianCurrency(order.grand_total)}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {formatIndianCurrency(order.paid_amount)}
        </CustomReportTableCell>


        <CustomReportTableCell>
          {formatIndianCurrency(order.balance_amount)}
        </CustomReportTableCell>

        <CustomReportTableCell>
          <StatusUpdate status={order.status} />
        </CustomReportTableCell>
      </CustomReportTableRow>
    ));

  const calculateTotals = (
    <CustomReportTableRow
      CustomReportTableRow
      id={"id"}
      key={"id"}
      bodyStyles={{
        borderTop: "1px solid #CCC",
        backgroundColor: "lightgray",
      }}
    >
      <CustomReportTableCell>
        <b>{"Total"}</b>
      </CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>

      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell>
        <b>
          {formatIndianCurrency(
            calculateTotalWithReduce(departureReport, "grand_total")
          )}
        </b>
      </CustomReportTableCell>
      <CustomReportTableCell>
        <b>
          {formatIndianCurrency(
            calculateTotalWithReduce(departureReport, "paid_amount")
          )}
        </b>
      </CustomReportTableCell>
      
      <CustomReportTableCell>
        <b>
          {formatIndianCurrency(
            calculateTotalWithReduce(departureReport, "balance_amount")
          )}
        </b>
      </CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
    </CustomReportTableRow>
  );
  return (
    <>
      <div
        id="receipt"
        className="room-types-full-container reports-new-background"
      >
        <div className="room-types-full-container-div">
          <div
            className="flex flex_gap_5 "
            style={{ alignItems: "unset" }}
            id="pdf"
          >
            <div className="room-type-heading">No-Show Report</div>
            <div
              className="hoverCursor"
              onClick={() => setInformationPopup(true)}
              id="ignoreFromPrint"
            >
              <SvgGetInfo />
            </div>
          </div>
          {/* <div id="pdf">  */}

          {departureReport?.length > 0 && (
            <div className="flex flex_gap_15" id="ignoreFromPrint">
              <ButtonToDownloadTablePDF
                itemToDownloadID={"receipt"}
                downloadFileName={`no_show_report`}
                ignoreFromPrint={"ignoreFromPrint"}
                pdfOrientation={"landscape"}
                longPdf={true}
              />
              <DownloadCSVButton
                downloadFileName={"no_show_report"}
                itemToDownloadID={"receipt"}
              />
            </div>
          )}
        </div>
        {/* </div> */}

        {departureReport && departureReport.length > 0 ? (
          <>
            {/* <LegacyCard>
              <IndexTable
                resourceName={resourceName}
                itemCount={departureReport.length}
                selectable={false}
                headings={[
                  { title: "Booking ID" },
                  { title: "Guest Name" },
                  { title: "Arrival Date" },
                  { title: "Departure Date" },
                  { title: "Room Type" },
                  { title: "Source" },
                  { title: "Status" },
                ]}
              >
                {rowMarkup}
              </IndexTable>
            </LegacyCard> */}

            <CustomReportTable
              headings={[
                "Booking ID",
                "Guest Name",
                "Booking Date",
                "Check In Date",
                "Check Out Date",
                "Room Type",
                "No Of Nights",
                "No Of Guests",
                "Grand Total",
                "Paid Amount",
                "Balance",
                "Status",
              ]}
              // commonHeadings={[
              //   "",
              //   "",
              //   "",
              //   "",
              //   "Quantity",
              //   "Quantity",
              //   "Quantity",
              //   "Total Purchase Amount  (₹)",
              //   "Total Purchase Amount  (₹)",
              //   "Total Purchase Amount  (₹)",
              //   "Average Purchase Cost per unit (₹)",
              //   "Average Purchase Cost per unit (₹)",
              //   "Average Purchase Cost per unit (₹)",
              // ]}
              headerStyles={{
                backgroundColor: "#dddddd",
                fontSize: "12px",
                // textAlign: "center",
              }}
              tableStyles={{ textAlign: "left" }}
            >
              {rowMarkup}
              {calculateTotals}
            </CustomReportTable>
          </>
        ) : (
          <NoDataFound />
        )}
      </div>

      {informationPopup && (
        <InformationCustomPopup
          setInformationPopup={setInformationPopup}
          title="No-Show Report"
        >
          <div className="mb-20">
            {/* <h2 className="mb-10"> Accordion Component </h2> */}
            <AccordionComponent
              questions={[
                {
                  question: "What is the No-Show Report?",
                  answer: (
                    <p>
                      The <b>No-Show Report</b> provides a detailed summary of
                      all bookings where guests did not check in as scheduled,
                      without canceling their reservation. This report is
                      crucial for understanding guest behavior and managing room
                      availability more efficiently.
                    </p>
                  ),
                },
                {
                  question: "Why is the No-Show Report Important? ",
                  answer: (
                    <div>
                      <p>
                        The No-Show Report is essential for optimizing hotel
                        operations and improving booking management. Here's why
                        it's important:
                      </p>

                      <ul>
                        <li>
                          <b>Revenue Management: </b>No-shows can result in lost
                          revenue if not properly managed. Understanding no-show
                          patterns helps refine policies and minimize financial
                          losses.
                        </li>
                        <li>
                          <b>Improve Booking Policies: </b>By analyzing the
                          reasons for no-shows, you can adjust your booking and
                          deposit policies to reduce occurrences.
                        </li>
                        <li>
                          <b>Room Availability Optimization: </b>Tracking
                          no-shows allows you to quickly release rooms for new
                          bookings, ensuring maximum occupancy.
                        </li>
                        <li>
                          <b>Guest Relationship Management: </b>Identify repeat
                          no-show guests to manage expectations and adjust
                          strategies accordingly.
                        </li>
                      </ul>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </InformationCustomPopup>
      )}
    </>
  );
};
export default ReportNoShowTable;
