import { Icon } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import Loader from "../UI/Loader/Loader";
import {
  calculateNestedTotal,
  calculateTotalArrayObjectKey,
  formatIndianCurrency,
  getBaseUrl,
} from "../../utils/utils";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "../CustomComponents/CustomReportComponent/CustomReportComponent";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";
import { useState } from "react";
import InformationCustomPopup from "../InformationCustomPopup/InformationCustomPopup";
import AccordionComponent from "../NewCustomComp/accordionComponent/AccordionComponent";
import { SvgGetInfo } from "../../assets/svgIcons";
import BookingDetailSliderNew from "../CustomComponents/BookingDetails/BookingDetailsSliderNew";
import { showErrorToast } from "../../assets/toastUtils";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { CancelMajor } from "@shopify/polaris-icons";
import InvoiceIframePopup from "./InvoiceIframePopup/InvoiceIframePopup";

const ReportSaleSummeryReport = ({
  departureReport,
  // loader,
  fullData,
  donShowTitle,
  userToken,
  property_id,
}) => {
  const styles = {
    tableData: {
      verticalAlign: "top",
      textAlign: "left",
    },
  };
  const [informationPopup, setInformationPopup] = useState(false);
  const commonHeadings = [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Room Sale",
    "Room Sale",
    "Room Sale",
    "Room Sale",
    "Room Sale",
    "F&B Sale",
    "F&B Sale",
    "F&B Sale",
    "F&B Sale",
    "F&B Sale",
    "Housekeeping Sale",
    "Housekeeping Sale",
    "Housekeeping Sale",
    "Housekeeping Sale",
    "Housekeeping Sale",
    "Add Ons Sale",
    "Add Ons Sale",
    "Add Ons Sale",
    "Add Ons Sale",
    "",
    "Payment Details",
    "Payment Details",
    "Payment Details",
    "Payment Details",
    "Payment Details",
  ];

  const commonColors = [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "rgb(236, 241, 255)",
    "rgb(236, 241, 255)",
    "rgb(236, 241, 255)",
    "rgb(236, 241, 255)",
    "rgb(236, 241, 255)",
    "rgb(223, 248, 223)",
    "rgb(223, 248, 223)",
    "rgb(223, 248, 223)",
    "rgb(223, 248, 223)",
    "rgb(223, 248, 223)",
    "rgb(255, 244, 210)",
    "rgb(255, 244, 210)",
    "rgb(255, 244, 210)",
    "rgb(255, 244, 210)",
    "rgb(255, 244, 210)",
    "rgb(236, 241, 255)",
    "rgb(236, 241, 255)",
    "rgb(236, 241, 255)",
    "rgb(236, 241, 255)",
    
    "",
    "rgb(255, 234, 234)",
    "rgb(255, 234, 234)",
    "rgb(255, 234, 234)",
    "rgb(255, 234, 234)",
    "rgb(255, 234, 234)",
  ];
  const tableHeadings = [
    "INV Date",
    "INV No.",

    "Booking ID",
    "Booking Source",
    "Guest Name",
    "Arrival Date",
    "Departure Date",
    "No. Of Nights",
    "Adults",
    "Child",
    "No. Of Rooms",

    // rooms sale
    "Room Sale Amount",
    "Taxable Amount",
    "Tax Amount",
    "Tax Type",
    "Net Room Sale",
    //  end room sale

    // f and b sale
    "F&B Sale Amt.",
    "Taxable Amount",
    "Tax Amount",
    "Tax Type",
    "Net F&B Sale",
    // f and b sale end

    // house keeping
    "HouseKeeping Sale Amt.",
    "Taxable Amount",
    "Tax Amount",
    "Tax Type",
    "Net HouseKeeping Sale",
    //  house keeping end     



    //  add on sale
    "Add Ons Sale",
    "Taxable Amt.",
    "Tax Amt.",
    "Net Add-On Sale",
    //  add on sale end

    "Total Bill Amount",

    //payments
    "Advance",
    "Cash",
    "Debit Card/ UPI/ Credit Card/ Bank Transfer",
    "Company Credit",
    "Balance",
  ];

  const rowMarkup =
    fullData &&
    fullData?.data?.length > 0 &&
    fullData?.data?.map((order) => (
      <CustomReportTableRow id={order.id} key={order.id} position={order.index}>
        <CustomReportTableCell styles={styles.tableData}  groupBackgroundColors={commonColors[0]}>
          {order.invoice_date ? (
            <TextDateFormatter date={order.invoice_date} as={"span"} />
          ) : (
            "-"
          )}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}  groupBackgroundColors={commonColors[1]}>
          {order.invoice_no ? (
            <span
              style={{ cursor: "pointer", color: "blue" }}
              onClick={() => handleSubmitPreview(order?.master_folio_id)}
            >
              {" "}
              #{order.invoice_no}
            </span>
          ) : (
            "-"
          )}
        </CustomReportTableCell>

        <CustomReportTableCell styles={styles.tableData}  groupBackgroundColors={commonColors[2]}>
          {order.booking_id ? (
            <span
              style={{ cursor: "pointer", color: "blue" }}
              onClick={() =>
                getSingleBookingDataHandler(order.unique_booking_id)
              }
            >
              #{order.unique_booking_id}
            </span>
          ) : (
            "-"
          )}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}  groupBackgroundColors={commonColors[3]}>
          {order.booking_source ? order.booking_source : "-"}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}  groupBackgroundColors={commonColors[4]}>
          {order.guest_name ? order.guest_name : "-"}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}  groupBackgroundColors={commonColors[5]}>
          {order.arrival_date ? (
            <TextDateFormatter date={order.arrival_date} as={"span"} />
          ) : (
            "-"
          )}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}  groupBackgroundColors={commonColors[6]}>
          {order.departure_date ? (
            <TextDateFormatter date={order.departure_date} as={"span"} />
          ) : (
            "-"
          )}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}  groupBackgroundColors={commonColors[7]}>
          {order.no_nights ? <>{order.no_nights}</> : "-"}
        </CustomReportTableCell>

        <CustomReportTableCell styles={styles.tableData}  groupBackgroundColors={commonColors[8]}>
          {order.no_adult ? <>{order.no_adult}</> : "-"}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}  groupBackgroundColors={commonColors[9]}>
          {order.no_child ? <>{order.no_child}</> : "-"}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}  groupBackgroundColors={commonColors[10]}>
          {order.no_of_rooms ? <>{order.no_of_rooms}</> : "-"}
        </CustomReportTableCell>

        {/* room sale */}

        <CustomReportTableCell styles={styles.tableData}  groupBackgroundColors={commonColors[11]}>
          {order?.room_charges?.length > 0
            ? order.room_charges.map((item) => (
                <>
                  {formatIndianCurrency(item.room_per_rate)} <br />
                </>
              ))
            : "-"}
        </CustomReportTableCell>

        <CustomReportTableCell styles={styles.tableData}  groupBackgroundColors={commonColors[12]}>
          {order?.room_charges?.length > 0
            ? order.room_charges.map((item) => (
                <>
                  {formatIndianCurrency(item.taxable_amount)} <br />
                </>
              ))
            : "-"}
        </CustomReportTableCell>

        <CustomReportTableCell styles={styles.tableData}  groupBackgroundColors={commonColors[13]}>
          {order?.room_charges?.length > 0
            ? order.room_charges.map((item) => (
                <>
                  {formatIndianCurrency(item.tax_amount)} <br />{" "}
                </>
              ))
            : "-"}
        </CustomReportTableCell>

        <CustomReportTableCell styles={styles.tableData}  groupBackgroundColors={commonColors[14]}>
          {order?.room_charges?.length > 0
            ? order.room_charges.map((item) => (
                <>
                  {item.tax_type === "inclusive" ? "incl" : "Excl"} <br />
                </>
              ))
            : "-"}
        </CustomReportTableCell>

        <CustomReportTableCell styles={styles.tableData}  groupBackgroundColors={commonColors[15]}>
          {order?.room_charges?.length > 0
            ? order.room_charges.map((item) => (
                <>
                  {formatIndianCurrency(item.net_total)} <br />{" "}
                </>
              ))
            : "-"}
        </CustomReportTableCell>

        {/* room sale end */}

        {/* f and b sale */}

        <CustomReportTableCell styles={styles.tableData}  groupBackgroundColors={commonColors[16]}>
          {order?.food_charges?.length > 0
            ? order.food_charges.map((item) => (
                <>
                  {formatIndianCurrency(item.room_per_rate)} <br />{" "}
                </>
              ))
            : "-"}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}  groupBackgroundColors={commonColors[17]}>
          {order?.food_charges?.length > 0
            ? order.food_charges.map((item) => (
                <>
                  {formatIndianCurrency(item.taxable_amount)}
                  <br />{" "}
                </>
              ))
            : "-"}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}  groupBackgroundColors={commonColors[18]}>
          {order?.food_charges?.length > 0
            ? order.food_charges.map((item) => (
                <>
                  {item.tax_amount} <br />
                </>
              ))
            : "-"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}  groupBackgroundColors={commonColors[19]}>
          {/* <div style={styles.tableData}> */}
          {order?.food_charges?.length > 0
            ? order.food_charges.map((item) => (
                <>
                  {item.tax_type}
                  <br />
                </>
              ))
            : "-"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}  groupBackgroundColors={commonColors[20]}>
          {order?.food_charges?.length > 0
            ? order.food_charges.map((item) => (
                <>
                  {formatIndianCurrency(item.net_total)}
                  <br />
                </>
              ))
            : "-"}
        </CustomReportTableCell>

        {/* f and b sale  end */}

        {/* house keeping sale */}

        <CustomReportTableCell styles={styles.tableData}  groupBackgroundColors={commonColors[21]}>
          {order?.houseKeepingCharges?.length > 0
            ? order.houseKeepingCharges.map((item) => (
                <>
                  {formatIndianCurrency(item.room_per_rate)} <br />{" "}
                </>
              ))
            : "-"}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}  groupBackgroundColors={commonColors[22]}>
          {order?.houseKeepingCharges?.length > 0
            ? order.houseKeepingCharges.map((item) => (
                <>
                  {formatIndianCurrency(item.taxable_amount)}
                  <br />{" "}
                </>
              ))
            : "-"}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}  groupBackgroundColors={commonColors[23]}>
          {order?.houseKeepingCharges?.length > 0
            ? order.houseKeepingCharges.map((item) => (
                <>
                  {item.tax_amount} <br />
                </>
              ))
            : "-"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}  groupBackgroundColors={commonColors[24]}>
          {/* <div style={styles.tableData}> */}
          {order?.houseKeepingCharges?.length > 0
            ? order.houseKeepingCharges.map((item) => (
                <>
                  {item.tax_type}
                  <br />
                </>
              ))
            : "-"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}  groupBackgroundColors={commonColors[25]}>
          {order?.houseKeepingCharges?.length > 0
            ? order.houseKeepingCharges.map((item) => (
                <>
                  {formatIndianCurrency(item.net_total)}
                  <br />
                </>
              ))
            : "-"}
        </CustomReportTableCell>


        {/* house keeping sale end */}

        {/* add on sale  */}
        <CustomReportTableCell styles={styles.tableData}  groupBackgroundColors={commonColors[26]}>
          {order?.add_on_charges?.length > 0
            ? order.add_on_charges.map((item) => (
                <>
                  {formatIndianCurrency(item.room_per_rate)}
                  <br />{" "}
                </>
              ))
            : "-"}
        </CustomReportTableCell>

        <CustomReportTableCell styles={styles.tableData}  groupBackgroundColors={commonColors[27]}>
          {order?.add_on_charges?.length > 0
            ? order.add_on_charges.map((item) => (
                <>
                  {formatIndianCurrency(item.taxable_amount)}
                  <br />
                </>
              ))
            : "-"}
        </CustomReportTableCell>

        <CustomReportTableCell styles={styles.tableData}  groupBackgroundColors={commonColors[28]}>
          {order?.add_on_charges?.length > 0
            ? order.add_on_charges.map((item ,index) => (
                <>
                  {item.tax_amount} {index > order.add_on_charges - 1 ? "" : ","} <br />
                </>
              ))
            : "-"}
        </CustomReportTableCell>

        <CustomReportTableCell styles={styles.tableData}  groupBackgroundColors={commonColors[29]}>
          {order?.add_on_charges?.length > 0
            ? order.add_on_charges.map((item ,index) => (
                <>
                  {formatIndianCurrency(item.net_total)}
                  {index > order.add_on_charges - 1 ? "" : ","}
                  <br />
                </>
              ))
            : "-"}
        </CustomReportTableCell>

        {/* add on sale end */}

        {/* total bill amount */}
        <CustomReportTableCell styles={styles.tableData} groupBackgroundColors={commonColors[30]} >
          {formatIndianCurrency(order?.total_bill_amount)}
        </CustomReportTableCell>
        {/* end total bill amount */}

        {/* payments */}

        <CustomReportTableCell styles={styles.tableData} groupBackgroundColors={commonColors[31]}>
          {formatIndianCurrency(order?.payment_summary?.advance_payment)}
        </CustomReportTableCell>

        <CustomReportTableCell styles={styles.tableData} groupBackgroundColors={commonColors[32]}>
          {formatIndianCurrency(order?.payment_summary?.cash)}
        </CustomReportTableCell>

        <CustomReportTableCell styles={styles.tableData} groupBackgroundColors={commonColors[33]}>
          {formatIndianCurrency(order?.payment_summary?.cash)}
        </CustomReportTableCell>

        <CustomReportTableCell styles={styles.tableData} groupBackgroundColors={commonColors[34]}>
          {formatIndianCurrency(order?.payment_summary?.city_ledger)}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData} groupBackgroundColors={commonColors[35]} >
          {formatIndianCurrency(
            order?.total_bill_amount - order.total_paid_amount
          )}
        </CustomReportTableCell>
      </CustomReportTableRow>
    ));

  const downloadData = departureReport.map((report) => {
    return {
      date: report.date,
      guest_name: report.guest_name ? report.guest_name : "-",
      reference: report.guest_name
        ? `#${report.unique_booking_id} Folio-${report.folio_no} (${report.room_no})`
        : "Paid Credit",
      credits: report.credit_amount,
      debits: report.debit_amount,
      available_credit_limit: report.available_credit_limit,
    };
  });

  const names = [
    {
      Invoice_No: "INV No.",
      Inv_Date: "Date",
      Booking_Ref: "Booking Ref",
      Primary_Guest: "Guest",
      GSTIN: "GST IN",
      Account_Details: "Account",
      Tax_Rate: "Tax Rate",
      Taxable_Amount: "Taxable Amt.",
      SGST_Amount: "SGST",
      CGST_Amount: "CGST",
      Invoice_Value: "INV Value",
    },
  ];

  const [openSingleBookingDetails, setOpenSingleBookingDetails] =
    useState(false);
  const [bookingDetails, setBookingDetails] = useState(null);
  const [folios, setFolios] = useState([]);
  const [chargesDetails, setChargesDetails] = useState(null);
  const [masterFolioDetails, setMasterFolioDetails] = useState(null);
  const [loader, setLoader] = useState(false);

  const getSingleBookingDataHandler = (bookingId) => {
    // setLoader(true);
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/get/booking/${bookingId}/${property_id?.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setOpenSingleBookingDetails(true);
          setLoader(false);
          setBookingDetails(data?.booking);
          setChargesDetails(data?.charges);
          setMasterFolioDetails(data?.masterFolio);
          setFolios(data?.allFolios);
        } else {
          setLoader(false);
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.error("Error:", error);
        // showErrorToast("An error occurred while processing your request.");
      });
  };

  const [iframeSrc, setIframeSrc] = useState("");
  const [showIframePopup, setShowIframePopup] = useState(false);
  const [showBillPreview, setShowBillPreview] = useState(false);
  const [iframeContent, setIframeContent] = useState("");

  const handleSubmitPreview = async (booking_id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/layout/preview/${property_id?.id}/bill?previewType=roomBillPreview&previewId=${booking_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const iframeContent = await response.text(); // API response as text (iframe content)
      setIframeContent(iframeContent);
      setShowIframePopup(true);
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
      setLoader(false);
    }
  };
  

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="room-types-full-container" id="sales_table">
          <div className="room-types-full-container-div">
            <div className="flex flex_gap_5 " style={{ alignItems: "unset" }}>
              <div className="room-type-heading">Detailed Business Report</div>
              <div
                className="hoverCursor"
                onClick={() => setInformationPopup(true)}
                id="ignoreFromPrint"
              >
                <SvgGetInfo />
              </div>
            </div>
            {fullData?.data?.length > 0 && (
              <div className="flex flex_gap_15" id="ignoreFromPrint">
                <ButtonToDownloadTablePDF
                  itemToDownloadID={"sales_table"}
                  downloadFileName={"detailed-business-report"}
                  ignoreFromPrint={"ignoreFromPrint"}
                  pdfOrientation={"landscape"}
                  pageFormat={"A1"}
                  longPdf={true}
                />
                <DownloadCSVButton
                  itemToDownloadID={"sales_table"}
                  downloadFileName={"detailed-business-report"}
                />
              </div>
            )}
          </div>
          {fullData && fullData?.data?.length > 0 ? (
            <>
              <CustomReportTable
                groupBackgroundColors={commonColors}
                headings={tableHeadings}
                commonHeadings={commonHeadings}
                headerStyles={{
                  backgroundColor: "#dddddd",
                  fontSize: "12px",
                  textAlign: "left",
                  width: "max-content",
                }}
                tableStyles={{ textAlign: "left", width: "max-content" }}
              >
                {rowMarkup}
                {/* {calculateTotals} */}
                <CustomReportTableRow>
                  <CustomReportTableCell
                    groupBackgroundColors={commonColors[0]}
                  >
                    <b>Total</b>
                  </CustomReportTableCell>

                  <CustomReportTableCell
                    groupBackgroundColors={commonColors[1]}
                  />

                  <CustomReportTableCell
                    groupBackgroundColors={commonColors[2]}
                  />

                  <CustomReportTableCell
                    groupBackgroundColors={commonColors[3]}
                  />

                  <CustomReportTableCell
                    groupBackgroundColors={commonColors[4]}
                  />

                  <CustomReportTableCell
                    groupBackgroundColors={commonColors[5]}
                  />

                  <CustomReportTableCell
                    groupBackgroundColors={commonColors[6]}
                  />

                  <CustomReportTableCell
                    groupBackgroundColors={commonColors[7]}
                  />

                  <CustomReportTableCell
                    groupBackgroundColors={commonColors[8]}
                  />

                  <CustomReportTableCell
                    groupBackgroundColors={commonColors[9]}
                  />

                  <CustomReportTableCell
                    groupBackgroundColors={commonColors[10]}
                  />

                  <CustomReportTableCell
                    groupBackgroundColors={commonColors[11]}
                  />
                  <CustomReportTableCell
                    groupBackgroundColors={commonColors[12]}
                  />

                  <CustomReportTableCell
                    groupBackgroundColors={commonColors[13]}
                  >
                    <b>
                      {formatIndianCurrency(
                        calculateNestedTotal(
                          fullData.data,
                          "room_charges",
                          "tax_amount"
                        )
                      )}
                    </b>
                  </CustomReportTableCell>

                  <CustomReportTableCell
                    groupBackgroundColors={commonColors[14]}
                  />

                  <CustomReportTableCell
                    groupBackgroundColors={commonColors[15]}
                  >
                    <b>
                      {formatIndianCurrency(
                        calculateNestedTotal(
                          fullData.data,
                          "room_charges",
                          "net_total"
                        )
                      )}
                    </b>
                  </CustomReportTableCell>

                  <CustomReportTableCell
                    groupBackgroundColors={commonColors[16]}
                  />

                  <CustomReportTableCell
                    groupBackgroundColors={commonColors[17]}
                  />

                  <CustomReportTableCell
                    groupBackgroundColors={commonColors[18]}
                  >
                    <b>
                      {formatIndianCurrency(
                        calculateNestedTotal(
                          fullData.data,
                          "food_charges",
                          "tax_amount"
                        )
                      )}
                    </b>
                  </CustomReportTableCell>

                  <CustomReportTableCell
                    groupBackgroundColors={commonColors[19]}
                  />

                  <CustomReportTableCell
                    groupBackgroundColors={commonColors[20]}
                  >
                    <b>
                      {formatIndianCurrency(
                        calculateNestedTotal(
                          fullData.data,
                          "food_charges",
                          "net_total"
                        )
                      )}
                    </b>
                  </CustomReportTableCell>
                  {/* house keeping */}
                  <CustomReportTableCell
                    groupBackgroundColors={commonColors[21]}
                  >
                   
                  </CustomReportTableCell>
                
                  <CustomReportTableCell
                    groupBackgroundColors={commonColors[22]}
                  >
                   
                  </CustomReportTableCell>
                  <CustomReportTableCell
                    groupBackgroundColors={commonColors[23]}
                  >
                    <b>
                      {formatIndianCurrency(
                        calculateNestedTotal(
                          fullData.data,
                          "houseKeepingCharges",
                          "tax_amount"
                        )
                      )}
                    </b>
                  </CustomReportTableCell>
                  <CustomReportTableCell
                    groupBackgroundColors={commonColors[24]}
                  >
                   
                  </CustomReportTableCell>
                  <CustomReportTableCell
                    groupBackgroundColors={commonColors[25]}
                  >
                    <b>
                    {formatIndianCurrency(
                        calculateNestedTotal(
                          fullData.data,
                          "houseKeepingCharges",
                          "net_total"
                        )
                      )}
                    </b>
                  </CustomReportTableCell>


                  <CustomReportTableCell
                    groupBackgroundColors={commonColors[26]}
                  />
                  <CustomReportTableCell
                    groupBackgroundColors={commonColors[27]}
                  />

                  <CustomReportTableCell
                    groupBackgroundColors={commonColors[28]}
                  >
                    <b>
                      {formatIndianCurrency(
                        calculateNestedTotal(
                          fullData.data,
                          "add_on_charges",
                          "tax_amount"
                        )
                      )}
                    </b>
                  </CustomReportTableCell>

                  <CustomReportTableCell
                    groupBackgroundColors={commonColors[29]}
                  >
                    <b>
                      {formatIndianCurrency(
                        calculateNestedTotal(
                          fullData.data,
                          "add_on_charges",
                          "net_total"
                        )
                      )}
                    </b>
                  </CustomReportTableCell>

                  <CustomReportTableCell
                    groupBackgroundColors={commonColors[30]}
                  >
                    <b>
                      {formatIndianCurrency(
                        calculateTotalArrayObjectKey(
                          fullData.data,
                          "total_bill_amount"
                        )
                      )}
                    </b>
                  </CustomReportTableCell>

                  <CustomReportTableCell
                    groupBackgroundColors={commonColors[31]}
                  >
                    <b>
                      {formatIndianCurrency(
                        calculateTotalArrayObjectKey(
                          fullData.data,
                          "payment_summary.advance_payment"
                        )
                      )}
                    </b>
                  </CustomReportTableCell>
                  <CustomReportTableCell
                    groupBackgroundColors={commonColors[32]}
                  >
                    <b>
                      {formatIndianCurrency(
                        calculateTotalArrayObjectKey(
                          fullData.data,
                          "payment_summary.cash"
                        )
                      )}
                    </b>
                  </CustomReportTableCell>

                  <CustomReportTableCell
                    groupBackgroundColors={commonColors[33]}
                  >
                    <b>
                      {formatIndianCurrency(
                        calculateTotalArrayObjectKey(
                          fullData.data,
                          "payment_summary.cash"
                        )
                      )}
                    </b>
                  </CustomReportTableCell>

                  <CustomReportTableCell
                    groupBackgroundColors={commonColors[34]}
                  >
                    <b>
                      {formatIndianCurrency(
                        calculateTotalArrayObjectKey(
                          fullData.data,
                          "payment_summary.city_ledger"
                        )
                      )}
                    </b>
                  </CustomReportTableCell>

                  <CustomReportTableCell
                    groupBackgroundColors={commonColors[35]}
                  >
                    <b>
                      {formatIndianCurrency(
                        calculateTotalArrayObjectKey(
                          fullData.data,
                          "total_bill_amount"
                        ) -
                          calculateTotalArrayObjectKey(
                            fullData.data,
                            "total_paid_amount"
                          )
                      )}
                    </b>
                  </CustomReportTableCell>
                </CustomReportTableRow>

                <CustomReportTableRow>
                  <CustomReportTableCell
                    groupBackgroundColors={"#ddd"}
                    styles={{ textAlign: "left" ,border:"none" }}
                    // colspan={tableHeadings.length}
                  >
                    <b>Summary</b>
                  </CustomReportTableCell>
                  <CustomReportTableCell
                    groupBackgroundColors={"#ddd"}
                    styles={{ textAlign: "left" }}
                    colspan={tableHeadings.length-1}
                  />

                </CustomReportTableRow>

                <CustomReportTableRow>
                  <CustomReportTableCell>
                    Total Number of Bookings
                  </CustomReportTableCell>
                  <CustomReportTableCell
                    styles={{ textAlign: "left" }}
                    
                  >
                    {fullData?.overall_totals?.overall_bookings || "-"}
                  </CustomReportTableCell>
                  <CustomReportTableCell colspan={tableHeadings.length - 2}/>
                </CustomReportTableRow>

                <CustomReportTableRow>
                  <CustomReportTableCell>Total Room Sale</CustomReportTableCell>
                  <CustomReportTableCell
                    styles={{ textAlign: "left" }}
                    
                  >
                    {formatIndianCurrency(
                      fullData?.overall_totals?.overall_room_sale
                    ) || "-"}
                  </CustomReportTableCell>
                  <CustomReportTableCell colspan={tableHeadings.length - 2}/>

                </CustomReportTableRow>

                <CustomReportTableRow>
                  <CustomReportTableCell>Total F&B Sale</CustomReportTableCell>
                  <CustomReportTableCell
                    styles={{ textAlign: "left" }}
                    
                  >
                    {formatIndianCurrency(
                      fullData?.overall_totals?.net_revenue
                    ) || "-"}
                  </CustomReportTableCell>
                  <CustomReportTableCell colspan={tableHeadings.length - 2}/>

                </CustomReportTableRow>

                <CustomReportTableRow>
                  <CustomReportTableCell>
                    Total Add Ons Sale
                  </CustomReportTableCell>
                  <CustomReportTableCell
                    styles={{ textAlign: "left" }}
                    
                  >
                    {formatIndianCurrency(
                      fullData?.overall_totals?.overall_addon_sale
                    ) || "-"}

                  </CustomReportTableCell>
                  <CustomReportTableCell colspan={tableHeadings.length - 2}/>

                </CustomReportTableRow>
                <CustomReportTableRow>
                  <CustomReportTableCell>
                    Total Combined Sale
                  </CustomReportTableCell>
                  <CustomReportTableCell
                    styles={{ textAlign: "left" }}
                    
                  >
                    {formatIndianCurrency(
                      fullData?.overall_totals?.overall_combined_sale
                    ) || "-"}
                  </CustomReportTableCell>
                  <CustomReportTableCell colspan={tableHeadings.length - 2}/>

                </CustomReportTableRow>
                <CustomReportTableRow>
                  <CustomReportTableCell>
                    Total Balance Amount
                  </CustomReportTableCell>
                  <CustomReportTableCell
                    styles={{ textAlign: "left" }}
                    
                  >
                    {formatIndianCurrency(
                      fullData?.overall_totals?.overall_balance_amount
                    ) || "-"}
                  </CustomReportTableCell>
                  <CustomReportTableCell colspan={tableHeadings.length - 2}/>

                </CustomReportTableRow>
                <CustomReportTableRow>
                  <CustomReportTableCell>Net Revenue</CustomReportTableCell>
                  <CustomReportTableCell
                    styles={{ textAlign: "left" }}
                   
                  >
                    {formatIndianCurrency(
                      fullData?.overall_totals?.net_revenue
                    ) || "-"}
                  </CustomReportTableCell>
                  <CustomReportTableCell colspan={tableHeadings.length - 2}/>

                </CustomReportTableRow>
              </CustomReportTable>
            </>
          ) : (
            <NoDataFound />
          )}
        </div>
      )}

      {informationPopup && (
        <InformationCustomPopup
          setInformationPopup={setInformationPopup}
          title="Detailed Business Report"
        >
          <div className="mb-20">
            <AccordionComponent
              questions={[
                {
                  question: "What is the Detailed Business Report?",
                  answer: (
                    <p>
                      The <b>Detailed Business Report</b> is a detailed
                      financial document that tracks all payments received over
                      a specific date range, categorized by payment methods such
                      as cash, bank transfers, UPI, credit card, debit card, and
                      city ledger. This report provides a consolidated view of
                      all payment transactions, making it easier to reconcile
                      finances.
                    </p>
                  ),
                },
                {
                  question:
                    "What is the Importance of the Detailed Business Report?",
                  answer: (
                    <div>
                      <p>The Detailed Business Report is essential for:</p>

                      <ul>
                        <li>
                          <b>Financial Accuracy: </b>Ensures all payments are
                          accurately recorded and matched with corresponding
                          transactions, preventing discrepancies.
                        </li>
                        <li>
                          <b>Cash Flow Management: </b>Helps in tracking cash
                          inflows from different payment channels, providing a
                          clear picture of daily revenue.
                        </li>
                        <li>
                          <b>Audit Preparedness: </b> Provides a transparent
                          record of payment methods used, simplifying audits and
                          financial reporting.
                        </li>
                        <li>
                          <b>Operational Efficiency: </b>Helps in identifying
                          any pending or unmatched payments, ensuring smoother
                          financial operations.
                        </li>
                        <li>
                          <b>Fraud Prevention: </b>By reconciling payments
                          against bookings and invoices, this report helps
                          identify potential fraud or errors in financial
                          transactions.
                        </li>
                      </ul>
                      <p>
                        This report is a key tool for maintaining accurate and
                        organized financial records.
                      </p>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </InformationCustomPopup>
      )}
      {openSingleBookingDetails && (
        <BookingDetailSliderNew
          folios={folios}
          data={bookingDetails}
          chargesDetails={chargesDetails}
          masterFolioDetails={masterFolioDetails}
          setShowBookingDetails={setOpenSingleBookingDetails}
          getSingleBookingDataHandler={getSingleBookingDataHandler}
        />
      )}

      {showIframePopup && (
        <>
          <InvoiceIframePopup
            setShowIframePopup={setShowIframePopup}
            iframeContent={iframeContent}
          />
        </>
      )}
    </>
  );
};
export default ReportSaleSummeryReport;
