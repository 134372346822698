import NoDataFound from "../CustomComponents/NoDataFound";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import Loader from "../UI/Loader/Loader";
import {
  calculateTotalWithReduce,
  formatIndianCurrency,
} from "../../utils/utils";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";

import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "../CustomComponents/CustomReportComponent/CustomReportComponent";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import DownloadPdfTables from "../CustomComponents/DownloadPdfTables/DownloadPdfTables";
import InformationCustomPopup from "../InformationCustomPopup/InformationCustomPopup";
import { useState } from "react";
import AccordionComponent from "../NewCustomComp/accordionComponent/AccordionComponent";
import { SvgGetInfo } from "../../assets/svgIcons";
import BookingDetailSliderNew from "../CustomComponents/BookingDetails/BookingDetailsSliderNew";

const ReportArrivals = ({
  departureReport,
  loader,
  getSingleBookingDataHandler,
  bookingDetail,
  openSingleBookingDetails,
}) => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };
  const [informationPopup, setInformationPopup] = useState(false);

  // const getSingleBookingDataHandler = (bookingId) => {
  //   // setLoader(true);
  //   fetch(
  //     `${process.env.REACT_APP_BASE_URL}/api/v1/get/booking/${bookingId}/${property_id?.id}`,
  //     {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${userToken}`,
  //       },
  //     }
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data.success) {
  //         setOpenSingleBookingDetails(true);
  //         setLoader(false);
  //         setBookingDetails(data?.booking);
  //         setChargesDetails(data?.charges);
  //         setMasterFolioDetails(data?.masterFolio);
  //       } else {
  //         setLoader(false);
  //         showErrorToast(data.message);
  //       }
  //     })
  //     .catch((error) => {
  //       setLoader(false);
  //       console.error("Error:", error);
  //       // showErrorToast("An error occurred while processing your request.");
  //     });
  // };

  const rowMarkup = departureReport?.map((order) => (
    <CustomReportTableRow
      id={order.id}
      key={order.id}
      // selected={selectedResources.includes(order.id)}
      position={order.index}
    >
      <CustomReportTableCell>
        {" "}
        <div
          className="pointer"
          style={{
            color: "rgba(57, 104, 237, 1)",
          }}
          onClick={() => getSingleBookingDataHandler(order.unique_booking_id)}
        >
          #{order.unique_booking_id || ""}
        </div>
      </CustomReportTableCell>
      <CustomReportTableCell>
        {order.guest && order.guest.length > 0 && order.guest[0].guest_name}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {" "}
        <TextDateFormatter
          as={"span"}
          showYear={true}
          date={order.created_at}
        />
      </CustomReportTableCell>
      <CustomReportTableCell>
        {" "}
        <TextDateFormatter as={"span"} showYear={true} date={order.check_in} />
      </CustomReportTableCell>
      <CustomReportTableCell>
        <TextDateFormatter as={"span"} showYear={true} date={order.check_out} />
      </CustomReportTableCell>
      <CustomReportTableCell>
        {order.guest &&
          order.guest.length > 0 &&
          order?.items.map((item, index) => (
            <span key={index}>
              {item.room_type_name}
              {index !== order.items.length - 1 ? ", " : ""}
              <br />
            </span>
          ))}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {order.guest &&
          order.guest.length > 0 &&
          order?.items.map((item, index) => (
            <span key={index}>
              {item.no_nights}
              {index !== order.items.length - 1 ? ", " : ""}
              <br />
            </span>
          ))}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {order.guest &&
          order.guest.length > 0 &&
          order?.items.map((item) => <div>{item.no_adult}</div>)}
      </CustomReportTableCell>
      <CustomReportTableCell>₹{order.balance_amount}</CustomReportTableCell>
      <CustomReportTableCell>
        <StatusUpdate status={order.status} /> {}
      </CustomReportTableCell>
    </CustomReportTableRow>
  ));

  const calculateTotals = (
    <CustomReportTableRow
      CustomReportTableRow
      id={"id"}
      key={"id"}
      bodyStyles={{
        borderTop: "1px solid #CCC",
        backgroundColor: "lightgray",
      }}
    >
      <CustomReportTableCell>
        <b>{"Total"}</b>
      </CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>

      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell>
        <b>
          {formatIndianCurrency(
            calculateTotalWithReduce(departureReport, "balance_amount")
          )}
        </b>
      </CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
    </CustomReportTableRow>
  );
  const extractedData = departureReport?.map((item) => ({
    item_name: item?.item_name,
    supplier_name: item?.supplier_name,
    supplier_gst_no: item?.supplier_gst_no,
    unit_name: item?.unit_name,
    MTD_quantity: item?.MTD_quantity,
    QTD_quantity: item?.QTD_quantity,
    YTD_quantity: item?.YTD_quantity,
    MTD_purchase_amount: item?.MTD_purchase_amount,
    QTD_purchase_amount: item?.QTD_purchase_amount,
    YTD_purchase_amount: item?.YTD_purchase_amount,
    MTD_average_purchase_cost: item?.MTD_average_purchase_cost,
    QTD_average_purchase_cost: item?.QTD_average_purchase_cost,
    YTD_average_purchase_cost: item?.YTD_average_purchase_cost,
  }));

  const names = [
    {
      item_name: "Item Name	",
      supplier_name: "Supplier Name",
      supplier_gst_no: "GST No",
      unit_name: "Unit",
      MTD_quantity: "MTD	Quantity",
      QTD_quantity: " QTD	Quantity",
      YTD_quantity: "YTD Quantity",
      MTD_purchase_amount: "MTD Purchase Amount",
      QTD_purchase_amount: "QTD Purchase Amount",
      YTD_purchase_amount: "YTD Purchase Amount",
      MTD_average_purchase_cost: "MTD Average Purchase Cost",
      QTD_average_purchase_cost: "QTD Average Purchase Cost",
      YTD_average_purchase_cost: "YTD Average Purchase Cost",
    },
  ];
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div
          id="receipt"
          className="room-types-full-container reports-new-background"
        >
          <div className="room-types-full-container-div">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: "10px",
              }}
            >
              <div
                className="flex flex_gap_5"
                id="pdf"
                style={{ alignItems: "unset" }}
              >
                <div className="room-type-heading">Expected Arrival Report</div>
                <div
                  className="hoverCursor"
                  onClick={() => setInformationPopup(true)}
                  id="ignoreFromPrint"
                >
                  <SvgGetInfo />
                </div>
              </div>
              {departureReport.length > 0 && (
                <div
                  className="flex flex_gap_15 align_center"
                  id="ignoreFromPrint"
                >
                  <ButtonToDownloadTablePDF
                    itemToDownloadID={"receipt"}
                    downloadFileName={`detail_arrival_report`}
                    ignoreFromPrint={"ignoreFromPrint"}
                    pdfOrientation={"landscape"}
                    longPdf={true}
                  />
                  {/* <DownloadPdfTables  tableData={extractedData} tableNames={names} downloadFileName={`detail_arrival_report`}/> */}
                  <DownloadCSVButton
                    reportName={"detail_arrival_report"}
                    itemToDownloadID={"receipt"}
                  />
                </div>
              )}
            </div>
          </div>
          <div>
            {departureReport && departureReport.length > 0 ? (
              <>
                <CustomReportTable
                  headings={[
                    "Booking ID",
                    "Guest Name",
                    "Booking Date",
                    "Check In Date",
                    "Check Out Date",
                    "Room Category",
                    "No. of Nights",
                    "No. of Guests",
                    "Due Amount",
                    "Status",
                  ]}
                  // commonHeadings={[
                  //   "",
                  //   "",
                  //   "",
                  //   "",
                  //   "Quantity",
                  //   "Quantity",
                  //   "Quantity",
                  //   "Total Purchase Amount  (₹)",
                  //   "Total Purchase Amount  (₹)",
                  //   "Total Purchase Amount  (₹)",
                  //   "Average Purchase Cost per unit (₹)",
                  //   "Average Purchase Cost per unit (₹)",
                  //   "Average Purchase Cost per unit (₹)",
                  // ]}
                  headerStyles={{
                    backgroundColor: "#dddddd",
                    fontSize: "12px",
                  }}
                  tableStyles={{ textAlign: "left" }}
                >
                  {rowMarkup}
                  {calculateTotals}
                </CustomReportTable>
              </>
            ) : (
              <NoDataFound />
            )}
          </div>
        </div>
      )}

      {informationPopup && (
        <InformationCustomPopup
          setInformationPopup={setInformationPopup}
          title="Detailed Arrival Report"
        >
          <div className="mb-20">
            {/* <h2 className="mb-10"> Accordion Component </h2> */}
            <AccordionComponent
              questions={[
                {
                  question: "What is the Arrival Report?",
                  answer: (
                    <p>
                      The <b>Arrival Report</b> provides a comprehensive
                      overview of upcoming bookings, detailing all reservations
                      expected to arrive between the current date and any
                      specified future date. This report is invaluable for
                      effective planning and resource allocation, enabling you
                      to anticipate guest arrivals and manage occupancy
                      efficiently.
                    </p>
                  ),
                },
                {
                  question: "Why is the Arrival Report Important?",
                  answer: (
                    <div>
                      <p>
                        The Arrival Report plays a crucial role in helping
                        businesses stay ahead of their operations. By offering a
                        clear picture of expected check-ins, it allows for:
                      </p>

                      <ul>
                        <li>
                          <b>Optimal Resource Management: </b>Ensure the right
                          staffing and services are available for incoming
                          guests.
                        </li>
                        <li>
                          <b>Better Room Allocation: </b>Manage room categories
                          effectively based on upcoming demand.
                        </li>
                        <li>
                          <b>Proactive Customer Service: </b>Prepare for special
                          requests or VIP guests in advance, enhancing the guest
                          experience.
                        </li>
                      </ul>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </InformationCustomPopup>
      )}

      {/* {openSingleBookingDetail && (
        <BookingDetailSliderNew
          data={bookingDetails}
          chargesDetails={bookingDetails}
          masterFolioDetails={bookingDetails}
          setShowBookingDetails={setop}
          getSingleBookingDataHandler={getSingleBookingDataHandler}
        />
      )} */}
    </>
  );
};
export default ReportArrivals;
