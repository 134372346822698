import { Icon, IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import DownloadPdfTables from "../CustomComponents/DownloadPdfTables/DownloadPdfTables";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "../CustomComponents/CustomReportComponent/CustomReportComponent";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";
import { useState } from "react";
import InformationCustomPopup from "../InformationCustomPopup/InformationCustomPopup";
import AccordionComponent from "../NewCustomComp/accordionComponent/AccordionComponent";
import {
  calculateTotalWithReduce,
  formatIndianCurrency,
} from "../../utils/utils";
import { showErrorToast } from "../../assets/toastUtils";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { CancelMajor } from "@shopify/polaris-icons";
import InvoiceIframePopup from "./InvoiceIframePopup/InvoiceIframePopup";
const ReportGstComplianceReport = ({
  departureReport,
  fullData,
  getSingleBookingDataHandler,
  userToken,
  property_id,
}) => {
  const resourceName = {
    singular: "order",
    plural: "orders",

    //   { title: "Booking ID" },d
    //   { title: "Guest Name" },d
    //   { title: "Arrival Date" },d
    //   { title: "Departure Date" },d
    //   { title: "Source" },
    //   { title: "Grand Total" },
    //   { title: "Amount Paid" },
    //   { title: "Balance" },
    //   { title: "Status" },
  };
  const extractedData = departureReport?.map((order) => ({
    invoice_no: order?.invoice_no,
    invoice_date: order?.invoice_date,
    unique_booking_id: order?.unique_booking_id,
    guest_name: order?.guest_name,
    booking_source: order?.booking_source,
    gst: order?.gst,
    hsn_code: order?.hsn_code,
    charge_type: order.charge_type,
    tax_rate: order?.tax_rate,
    taxable_amount: order?.taxable_amount,
    cgst: order?.cgst,
    sgst: order?.sgst,
    total_tax_amount: order?.total_tax_amount,
    net_total: order?.net_total,
  }));

  const [informationPopup, setInformationPopup] = useState(false);

  const [iframeSrc, setIframeSrc] = useState("");
  const [showIframePopup, setShowIframePopup] = useState(false);
  const [showBillPreview, setShowBillPreview] = useState(false);
  const [iframeContent, setIframeContent] = useState("");
  const [downloadUrl, setDownloadUrl] = useState("");

  const names = [
    {
      invoice_no: "Invoice No.",
      invoice_date: "Invoice Date",
      unique_booking_id: "Booking ID",
      guest_name: "Guest Name",
      booking_source: "Booking Source",
      gst: "GST",
      hsn_code: "HSN Code",
      charge_type: "Charge Type",
      tax_rate: "Tax Rate",
      taxable_amount: "Taxable Amt.",
      cgst: "CGST",
      sgst: "SGST",
      total_tax_amount: "Total Tax Amt.",
      net_total: "Net Total",
    },
  ];

  const handleSubmitPreview = async (booking_id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/layout/preview/${property_id?.id}/bill?previewType=roomBillPreview&previewId=${booking_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const iframeContent = await response.text(); // API response as text (iframe content)

      // Save iframe content and control states
      setIframeSrc(iframeContent);
      setIframeContent(iframeContent);
      setShowIframePopup(true);
      setShowBillPreview(true);
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
      // setLoader(false);
    }
  };

  const rowMarkup = departureReport?.map((order) => (
    <CustomReportTableRow id={order.id} key={order.id} position={order.index}>
      <CustomReportTableCell>
        {order.invoice_no ? (
          <span
            style={{ cursor: "pointer", color: "blue" }}
            onClick={() => handleSubmitPreview(order?.folio_id)}
          >
            {" "}
            #{order.invoice_no}
          </span>
        ) : (
          "-"
        )}
      </CustomReportTableCell>
      {/* <CustomReportTableCell>
        {order?.guest[0]?.guest_name}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {" "}
        <TextDateFormatter as={"span"} showYear={true} date={order.check_in} />
      </CustomReportTableCell>
      <CustomReportTableCell>
        <TextDateFormatter as={"span"} showYear={true} date={order.check_out} />
      </CustomReportTableCell> */}
      <CustomReportTableCell>
        {order?.invoice_date ? order?.invoice_date : "-"}
      </CustomReportTableCell>

      <CustomReportTableCell>
        {order?.unique_booking_id ? (
          <div
            className="pointer"
            style={{
              color: "blue",
            }}
            onClick={() => getSingleBookingDataHandler(order.unique_booking_id)}
          >
            #{order.unique_booking_id || ""}
          </div>
        ) : (
          "-"
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {order?.guest_name ? order?.guest_name : "-"}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {order?.booking_source ? order?.booking_source : "-"}
      </CustomReportTableCell>

      <CustomReportTableCell>
        {order?.gst ? order?.gst : "-"}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {order?.hsn_code ? order?.hsn_code : "-"}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {order.charge_type ? order.charge_type : "-"}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {order?.tax_rate ? <>{order?.tax_rate}%</> : "-"}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {order?.taxable_amount ? (
          <>{formatIndianCurrency(order?.taxable_amount)}</>
        ) : (
          "-"
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {order?.cgst ? <>{formatIndianCurrency(order?.cgst)}</> : "-"}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {order?.sgst ? <>{formatIndianCurrency(order?.sgst)}</> : "-"}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {order?.total_tax_amount ? (
          <>{formatIndianCurrency(order?.total_tax_amount)}</>
        ) : (
          "-"
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {order?.net_total ? <>{formatIndianCurrency(order?.net_total)}</> : "-"}
      </CustomReportTableCell>
    </CustomReportTableRow>
  ));
 

  const calculateTotals = (
    <CustomReportTableRow
      CustomReportTableRow
      id={"id"}
      key={"id"}
      bodyStyles={{
        borderTop: "1px solid #CCC",
       
      }}
    >
      <CustomReportTableCell><b>{"Total"}</b></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>

      <CustomReportTableCell>
       <b>
       {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "taxable_amount")
        )}
       </b>
      </CustomReportTableCell>
      <CustomReportTableCell>
        <b>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "cgst")
        )}
        </b>

      </CustomReportTableCell>
      <CustomReportTableCell>
        <b>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "sgst")
        )}
        </b>
      </CustomReportTableCell>
      <CustomReportTableCell>
        <b>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "total_tax_amount")
        )}
        </b>
      </CustomReportTableCell>
      <CustomReportTableCell>
        <b>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "net_total")
        )}
        </b>
      </CustomReportTableCell>
    </CustomReportTableRow>
  );

 
  const tableHeadings = [
    "Invoice No.",
    "Invoice Date",
    "Booking ID",
    "Guest Name",
    "Booking Source",
    "GSTIN",
    "HSN/SAC Code",
    "Charge Description",
    "Tax Rate",
    "Subtotal",
    "CGST",
    "SGST",
    "Total Tax Amount",
    "Invoice Value",
  ]
  return (
    <>
      <div
        id="receipt"
        className="room-types-full-container reports-new-background"
      >
        <div className="room-types-full-container-div">
          <div className="flex flex_gap_5 " style={{ alignItems: "unset" }}>
            <div className="room-type-heading">GST Compliance Report</div>
            <div
              className="hoverCursor"
              onClick={() => setInformationPopup(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#3968ED"
              >
                <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
              </svg>
            </div>
          </div>
          {departureReport?.length > 0 && (
            <div className="flex flex_gap_15" id="doNotPrint">
              {/* <DownloadPdfTables
                tableData={extractedData}
                tableNames={names}
                downloadFileName="AccountBalanceReport"
              /> */}

              <ButtonToDownloadTablePDF
                downloadFileName="gst_Compliance_Report"
                itemToDownloadID={"receipt"}
                ignoreFromPrint={"doNotPrint"}
                longPdf={true}
                pageFormat={"A3"}
              />
              <DownloadCSVButton
                downloadFileName="gst_Compliance_Report"
                itemToDownloadID={"receipt"}
                repeatColspanHeading={true}
              />
            </div>
          )}
        </div>

        {departureReport && departureReport.length > 0 ? (
          <>

            <CustomReportTable
              headings={tableHeadings}
              headerStyles={{
                backgroundColor: "#dddddd",
                fontSize: "12px",
                textAlign: "left",
              }}
              tableStyles={{ textAlign: "left" }}
            >
              {rowMarkup}

              {calculateTotals}
              <CustomReportTableRow>
                <CustomReportTableCell groupBackgroundColors={ "lightgray"}  colspan={tableHeadings.length}>
                  <b>
                    Summery 
                  </b>
                  
                  </CustomReportTableCell> 
              </CustomReportTableRow>
              <CustomReportTableRow >
                  <CustomReportTableCell colspan={3}  >
                  Room Rent @12%
                  </CustomReportTableCell>
                  <CustomReportTableCell >
                    {formatIndianCurrency(
                      fullData &&
                        fullData.summary &&
                        fullData?.summary?.length > 0 &&
                        fullData.summary.find(
                          (item) =>
                            
                            item.tax_rate === 18
                        )?.net_total
                    )}
                  </CustomReportTableCell>
                  <CustomReportTableCell colspan={tableHeadings.length - 4}/>
                </CustomReportTableRow>
                <CustomReportTableRow >
                  <CustomReportTableCell colspan={3} >
                    Room Rent @18%
                  </CustomReportTableCell>
                  <CustomReportTableCell >
                    {formatIndianCurrency(
                      fullData &&
                        fullData.summary &&
                        fullData?.summary?.length > 0 &&
                        fullData.summary.find(
                          (item) =>
                            item.tax_rate === 12
                        )?.net_total
                    )}
                  </CustomReportTableCell>
                  <CustomReportTableCell colspan={tableHeadings.length - 4}/>
                </CustomReportTableRow>
                <CustomReportTableRow >
                  <CustomReportTableCell colspan={3} >
                    <b>Total</b>
                  </CustomReportTableCell>
                  <CustomReportTableCell >
                    <b>
                      {formatIndianCurrency(
                        fullData &&
                          fullData.summary &&
                          fullData?.summary?.length > 0 &&
                          fullData.summary.reduce(
                            (a, b) => Number(a) + Number(b.net_total),
                            0
                          )
                      )}
                    </b>
                  </CustomReportTableCell>
                  <CustomReportTableCell colspan={tableHeadings.length - 4}/>
                </CustomReportTableRow>
            </CustomReportTable>
          </>
        ) : (
          <NoDataFound />
        )}
      

        {informationPopup && (
          <InformationCustomPopup
            setInformationPopup={setInformationPopup}
            title="Information of GST Compliance Report"
          >
            <div className="mb-20">
              <AccordionComponent
                questions={[
                  {
                    question: "What is the GST Compliance Report?",
                    answer: (
                      <p>
                        The <b>GST Compliance Report</b> is a detailed financial
                        report that tracks all taxable transactions within a
                        specified date range to ensure proper Goods and Services
                        Tax (GST) compliance. It includes essential data such as
                        invoice numbers, booking IDs, guest information, booking
                        sources, GST identification numbers (GSTIN), HSN/SAC
                        codes, tax rates, and a breakdown of taxable amounts and
                        corresponding GST charges (CGST, SGST).
                      </p>
                    ),
                  },
                  {
                    question:
                      "What is the Importance of the GST Compliance Report?",
                    answer: (
                      <div>
                        <p>The GST Compliance Report is crucial for:</p>

                        <ul>
                          <li>
                            <b>Regulatory Compliance: </b>Ensures all
                            transactions meet GST regulations and simplifies tax
                            filings.
                          </li>
                          <li>
                            <b>Accurate Taxation: </b>Provides transparency in
                            tax rates and taxable amounts across different
                            categories (room charges, F&B, etc.).
                          </li>
                          <li>
                            <b>Financial Auditing: </b> Facilitates accurate
                            financial record-keeping for auditing purposes.
                          </li>
                          <li>
                            <b>Operational Clarity: </b>Breaks down tax rates
                            applied to various services, helping management
                            track tax-related expenses efficiently.
                          </li>
                          <li>
                            <b>Tax Management: </b> Helps in identifying tax
                            liabilities and reclaiming eligible credits.
                          </li>
                        </ul>
                        <p>
                          This report is essential for maintaining tax
                          compliance and simplifying the hotel’s financial
                          operations under the GST framework.{" "}
                        </p>
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          </InformationCustomPopup>
        )}

        {showIframePopup && (
          <InvoiceIframePopup
            setShowIframePopup={setShowIframePopup}
            iframeContent={iframeContent}
          />
        )}
      </div>
    </>
  );
};
export default ReportGstComplianceReport;
