import React from "react";
import { SvgCancel, SvgDownload, SvgPrint } from "../../../assets/svgIcons";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { showErrorToast } from "../../../assets/toastUtils";
import { Sticky } from "@shopify/polaris";

const InvoiceIframePopup = ({ setShowIframePopup, iframeContent }) => {
  const updatedHtml = iframeContent.replace(/\.pageFooter\s*\{[^}]*\}/g, "");
 
  const printIframe = () => {
    const iframe = document.getElementById("print-iframe"); // Ensure it's an actual iframe element
    if (!iframe) {
        showErrorToast("Iframe not found");
        return;
    }

    const iframeWindow = iframe.contentWindow || iframe;
    if (!iframeWindow) {
        showErrorToast("Iframe window not found");
        return;
    }

    iframeWindow.focus();
    iframeWindow.print();
    return false;
};
 
  const handleDownload = () => {
    const pdf = new jsPDF("p", "in", "letter"); // Letter size (8.5x11 inches)
    const pageWidth = 8.5; // Width in inches
    const pageHeight = 10; // Height in inches
    const margin = 0.2; // Half-inch margin
  
    // Define the content you want to add (iframeContent as plain HTML)
    const content = updatedHtml; // iframeContent contains your HTML as a string
  
    // Set up an invisible container to parse and style the content
    const tempContainer = document.createElement("div");
    tempContainer.innerHTML = content;
    tempContainer.style.width = `${pageWidth - margin * 2}in`; // Set the width to fit the PDF
    tempContainer.querySelectorAll(".labelTxt").forEach(el => el.classList.remove("labelTxt"));
    document.body.appendChild(tempContainer);
  
    // Add the HTML content directly to the PDF
    pdf.html(tempContainer, {
      x: margin, // X-coordinate for content
      y: .1, // Y-coordinate for content
      width: pageWidth - margin * 2, // Content width
      windowWidth: tempContainer.offsetWidth, // Match the container's width
      autoPaging: "text", // Enables automatic pagination for overflowing content
      callback: (doc) => {
        doc.save("receipt.pdf"); // Save the PDF
        document.body.removeChild(tempContainer); // Clean up
      },
    });
  };

// setHtmlContent(updatedHtml);
  // console.log(updatedHtml ,"iframeContent");
  return (
    <div>
      <div className="global_popup_container_background">
        <div className="global_popup_container" style={{width:"60%" ,height:"70vh" ,overflow:"auto"}}>
          <div className="global_popup_container_heading flex justify_content_between" style={{position:"sticky" ,top:"0"}}>
            Tax Invoice


           <div className="flex flex_gap_20 align_items_center">
           <span onClick={() => printIframe("print-iframe")} className="pointer">
                <SvgPrint />
              </span>
              <span onClick={handleDownload} className="pointer">
                <SvgDownload />
              </span>
           
            <span
              onClick={() => setShowIframePopup(false)}
              style={{ cursor: "pointer" }}
            >
              <SvgCancel color={"#333"} />
            </span>
           </div>
          </div>
          <div style={{ background: "#ddddd", height: "100%" }} >
            <iframe srcDoc={updatedHtml} title="Receipt" id="print-iframe" />
           
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceIframePopup;
