import React, { useEffect, useState } from "react";
import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "./NoDataFound";
import CustomCssComponentTableText from "./CustomCssComponentTableText";
import DownloadCsvTables from "./DownloadCsvTables/DownloadCsvTables";
import DownloadPdfTables from "./DownloadPdfTables/DownloadPdfTables";
import {
  calculateTotalArrayObjectKey,
  calculateTotalWithReduce,
  formatIndianCurrency,
} from "../../utils/utils";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "./CustomReportComponent/CustomReportComponent";
import ButtonToDownloadTablePDF from "./DownloadPdfTables/ButtonToDownloadTablePDF";
import DownloadCSVButton from "./DownloadCsvTables/DownloadCSVButton";
import InformationCustomPopup from "../InformationCustomPopup/InformationCustomPopup";
import AccordionComponent from "../NewCustomComp/accordionComponent/AccordionComponent";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";

const ReportOccupancyChart = ({ reportData }) => {
  const [informationPopup, setInformationPopup] = useState(false);

  const repeatItems = (num) => {
    const items = ["Total", "Sold", "Hold", "Occ %"];
    const startingArray = ["Date"];
    const endArray = ["Net Daily Occupancy"];
    const colors = ["#ecf1ff", "#dff8df", "#fff4d2", "#ffeaea"];
    const emptyColor = "";

    let headings = [...startingArray];
    let colorsArray = [emptyColor];

    for (let i = 0; i < num; i++) {
      headings = headings.concat(items);
      const color = colors[i % colors.length];
      for (let j = 0; j < items.length; j++) {
        colorsArray.push(color);
      }
    }

    headings = headings.concat(endArray);
    colorsArray.push(emptyColor);

    return { headings, colors: colorsArray };
  };
  const roomCat =
    reportData &&
    reportData.length > 0 &&
    reportData[0].rooms.map((room) => room.room_name);

  function repeatElements(inputArray, repeatCount) {
    console.log(inputArray, "inputArray");
    if (!Array.isArray(inputArray)) {
      return;
    }

    if (typeof repeatCount !== "number" || repeatCount <= 0) {
      return;
    }

    const emptyArray = [""];

    const array =
      inputArray &&
      inputArray.length > 0 &&
      inputArray.flatMap((item) => Array(repeatCount).fill(item));

    if (!array) {
      throw new TypeError("Failed to create the repeated array");
    }

    return [...emptyArray, ...array, ...emptyArray];
  }

  const repeatArrayLength =
    reportData && reportData.length > 0 && reportData[0].rooms.length;

  // console.log(repeatItems(repeatArrayLength)?.colors, "colors");

  const lastCellShowingTotal = () => {
    return (
      <>
        {repeatItems &&
          repeatItems(repeatArrayLength)?.colors.map((color, index) => {
            return (
              <>
                <CustomReportTableCell>Total </CustomReportTableCell>
              </>
            );
          })}
      </>
    );
  };
  console.log(repeatItems(repeatArrayLength) ,"repeat");

  console.log(repeatItems(repeatArrayLength)?.colors, "reportData");

  function calculateTotalTableDataWithKeys(data, roomName, keyName) {
    let total = 0;

    // Iterate through each date's data
    data.forEach((day) => {
        // Find the room with the specified name
        const room = day.rooms.find((r) => r.room_name === roomName);

        // If the room exists and has the specified key, add its value to the total
        if (room && room[keyName] !== undefined) {
            total += room[keyName];
        }
    });

    return total;
}
  return (
    <>
      <div
        id="receipt"
        className="room-types-full-container reports-new-background"
      >
        <div className="room-types-full-container-div">
          <div
            className="flex flex_gap_5 "
            style={{ alignItems: "unset" }}
            id="pdf"
          >
            <div className="room-type-heading">Occupancy Chart</div>

            <div
              className="hoverCursor"
              onClick={() => setInformationPopup(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#3968ED"
              >
                <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
              </svg>
            </div>
          </div>
          {reportData?.length > 0 && (
            <div className="flex flex_gap_15" id="pdf">
              <ButtonToDownloadTablePDF
                itemToDownloadID={"receipt"}
                downloadFileName={"occupancy_chart"}
                ignoreFromPrint={"pdf"}
                pageFormat={"A2"}
                pdfOrientation={"landscape"}
                longPdf={true}
              />
              {/* <DownloadCsvTables tableData={reportData} /> */}
              <DownloadCSVButton
                downloadFileName={"occupancy_chart"}
                itemToDownloadID={"receipt"}
              />
            </div>
          )}
        </div>

        {reportData && reportData.length > 0 && (
          <CustomReportTable
            headings={repeatItems(repeatArrayLength)?.headings}
            commonHeadings={repeatElements(roomCat, 4)}
            headerStyles={{
              backgroundColor: "#dddddd",
              fontSize: "12px",
              borderBottom: "1px solid #dddddd",
            }}
            tableStyles={{ textAlign: "left" }}
            groupBackgroundColors={repeatItems(repeatArrayLength)?.colors}
          >
            {reportData.map((item, index) => {
              const colorArray = repeatItems(repeatArrayLength)?.colors
              const getBodyColors  =  (inputArray) => {
                return inputArray.filter((color, index, arr) => color && color !== arr[index - 1]);
             }
             const color = getBodyColors(colorArray)
              


              // console.log(color, "color");

              return (
                <CustomReportTableRow
                  key={index}
                  bodyStyles={{ borderBottom: "1px solid #dddddd" }}
                >
                  <CustomReportTableCell
                    groupBackgroundColors={
                      repeatItems(repeatArrayLength)?.colors[0]
                    }
                  >
                    <TextDateFormatter
                      date={item.date}
                      as={"span"}
                      showYear={true}
                    />
                  </CustomReportTableCell>
                  {item.rooms.map(
                    (
                      {
                        sold_rooms,
                        total_rooms,
                        hold_rooms,
                        rooms_occupancy,
                        room_name,
                      },
                      i
                    ) => {
                      return (
                        <React.Fragment key={i}>
                          <CustomReportTableCell
                            groupBackgroundColors={color[i]}
                          >
                            {total_rooms}
                          </CustomReportTableCell>
                          <CustomReportTableCell
                            groupBackgroundColors={color[i]}
                          >
                            {sold_rooms}
                          </CustomReportTableCell>
                          <CustomReportTableCell
                            groupBackgroundColors={color[i]}
                          >
                            {hold_rooms}
                          </CustomReportTableCell>
                          <CustomReportTableCell
                            groupBackgroundColors={color[i] }
                          >
                            {rooms_occupancy}
                          </CustomReportTableCell>
                        </React.Fragment>
                      );
                    }
                  )}
                  <CustomReportTableCell
                    groupBackgroundColors={repeatItems(
                      repeatArrayLength
                    )?.colors?.slice(-1)}
                  >
                    {item.net_rooms_occupancy}
                  </CustomReportTableCell>
                </CustomReportTableRow>
              );
            })}

            <CustomReportTableRow bodyStyles={{fontWeight:"bold" , backgroundColor: "#dddddd",}}>
              <CustomReportTableCell>
               
                Overall Property Occupancy
              </CustomReportTableCell>
              {roomCat &&
                Array.from({ length: roomCat?.length }).map((_, index) => (
                  <React.Fragment key={index}>
                    <CustomReportTableCell>{calculateTotalTableDataWithKeys(reportData,roomCat[index] ,"total_rooms")}</CustomReportTableCell>
                    <CustomReportTableCell>{calculateTotalTableDataWithKeys(reportData,roomCat[index] ,"sold_rooms")}</CustomReportTableCell>
                    <CustomReportTableCell>{calculateTotalTableDataWithKeys(reportData,roomCat[index] ,"hold_rooms")}</CustomReportTableCell>
                    <CustomReportTableCell>{(calculateTotalTableDataWithKeys(reportData,roomCat[index]  ,"rooms_occupancy") / reportData.length).toFixed(2)}</CustomReportTableCell>
                  </React.Fragment>
                ))}

              <CustomReportTableCell> {(calculateTotalArrayObjectKey(reportData, "net_rooms_occupancy") / reportData.length ).toFixed(2)} </CustomReportTableCell>
            </CustomReportTableRow>
          </CustomReportTable>
        )}
      </div>

      {informationPopup && (
        <InformationCustomPopup
          setInformationPopup={setInformationPopup}
          title="Information of Occupancy Chart"
        >
          <div className="mb-20">
            <AccordionComponent
              questions={[
                {
                  question: "What is an Occupancy Chart?",
                  answer: (
                    <p>
                      An <b>Occupancy Chart</b> is a graphical or tabular
                      representation that tracks room occupancy over a specified
                      date range. It breaks down the total number of rooms
                      available, rooms sold, rooms on hold, and the occupancy
                      percentage for different room categories on each day.
                    </p>
                  ),
                },
                {
                  question: "What is the Importance of the Occupancy Chart?",
                  answer: (
                    <div>
                      <ul>
                        <li>
                          <b>Operational Insight: </b>Provides hotel management
                          with an overview of how many rooms are occupied, held,
                          or unsold for a particular period.
                        </li>
                        <li>
                          <b>Revenue Management: </b>Helps monitor demand trends
                          and adjust pricing, offers, or promotions to maximize
                          occupancy and revenue.
                        </li>
                        <li>
                          Aids in forecasting and managing staffing needs and
                          operational resources based on room occupancy levels.
                          hotels in analyzing which agents/OTAs generate the
                          most business, helping make informed decisions on
                          partnerships.
                        </li>
                        {/* <li>
                          <b>Strategic Decision-Making: </b>The report informs
                          decisions on marketing strategies, commission
                          structures, and partnerships to enhance business
                          growth.
                        </li>
                        <li>
                          <b>Operational Efficiency: </b> Understanding booking
                          trends through agents helps optimize inventory
                          management and resource allocation.
                        </li> */}
                      </ul>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </InformationCustomPopup>
      )}
    </>
  );
};
export default ReportOccupancyChart;
