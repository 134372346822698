import React, { useEffect } from "react";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  BgThemeButtonDisabled,
} from "../../UI/Buttons";
import GoBackButtonCustom from "../../GoBackButtonCustom/GoBackButtonCustom";
import { useState } from "react";
import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";
import { getDecryptedData } from "../../../utils/encryptStorage";
import { useSelector } from "react-redux";
import ToggleSwitch from "../../NewCustomComp/Switch/ToggleSwitch";

const AssignRoomPopupNew = ({
  setShowAssignRoomPopup,
  bookingId,
  mainData,
  getSingleBookingDataHandler,
  uniqueBookingId
}) => {
  const userToken = getDecryptedData("encryptToken");
  const property_id = useSelector((state) => state.property.value);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [selectedData, setSelectedData] = useState([]);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [assignButtonLoader, setAssignButtonLoader] = useState(false);
  const [assignRoomsData, setAssignRoomsData] = useState([]);
  const [selectedRoooms, setSelectedRooms] = useState({});
  const [loader, setLoader] = useState(false);
  const [showInHouseCheckIn, setShowInHouseCheckIn] = useState(false);

  const checkMaxRooms =
    selectedData &&
    selectedData.every((room) => {
      return (
        room.maxRooms === (room.selectedRoooms ? room.selectedRoooms.length : 0)
      );
    });

  const assignRoomsHandle = (e) => {
   const id = bookingId ? bookingId : mainData?.id
    if (!checkMaxRooms) {
      showErrorToast("Please assign the rooms first.");
      return;
    }

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/assign/room/no/${id}/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          assign_room: selectedData,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // setShowBookingDetails(false);
          // setBookingDetails(data.booking);
          setShowInHouseCheckIn(false);
          setShowAssignRoomPopup(false);
          showSuccessToast(data.message);
          // setShowDashBoardCheckIn(false);
          // window.location.reload();
          getSingleBookingDataHandler
            ? getSingleBookingDataHandler(mainData?.unique_booking_id)
            : window.location.reload();
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const fetchAssignRooms = async (propertyId) => {
    try {
      // setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/check-in/getdata/${bookingId ? bookingId: mainData?.id}/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        setAssignRoomsData(data.availablerooms);
        setLoader(false);
        //   setShowAssignRoomPopup(true);
      } else {
        showErrorToast("Something went wrong...");
      }
    } catch (error) {
      setLoader(false);
      showErrorToast("Something went wrong...");
      console.error(error);
    }
  };

  useEffect(() => {
    if (property_id?.id) {
      fetchAssignRooms(property_id?.id);
    }
  }, [property_id]);

  const handleCheckboxChange = (roomId, roomName, roomNoId, room_no) => {
    setSelectedRooms((prevSelectedRooms) => {
      const newSelectedRooms = { ...prevSelectedRooms };
      const currentSelection = newSelectedRooms[roomId] || [];

      if (currentSelection.includes(room_no)) {
        newSelectedRooms[roomId] = currentSelection.filter(
          (id) => id !== room_no
        );
      } else {
        const roomCategory = assignRoomsData.find((room) => room.id === roomId);
        const maxRooms = parseInt(roomCategory.no_of_rooms, 10);

        if (currentSelection.length < maxRooms) {
          newSelectedRooms[roomId] = [...currentSelection, room_no];
        } else {
          showErrorToast(
            `Cannot select more than ${maxRooms} rooms for ${roomName}`
          );
          return prevSelectedRooms;
        }
      }
      return newSelectedRooms;
    });
  };

  const handleRoomClick = (roomId, maxRooms) => {
    setSelectedRoom((prev) => (prev === roomId ? "" : roomId));
  };

  useEffect(() => {
    const newData = assignRoomsData.map((room) => ({
      name: room.room_name,
      id: room.id,
      selectedRoooms: selectedRoooms[room.id] || [],
      maxRooms: parseInt(room.no_of_rooms, 10),
    }));
    setSelectedData(newData);
  }, [selectedRoooms, assignRoomsData]);

  const handleToggle = (value) => {
    if (value) {
      setSelectedRooms(() => {
        const autoAssignedRooms = {};
        let errorOccurred = false;

        assignRoomsData.forEach((roomCategory) => {
          const maxRooms = parseInt(roomCategory.no_of_rooms, 10);
          const availableCleanRooms =
            roomCategory?.sorted_rooms
              .filter((room) => room.status === "clean")
              .map((room) => room.room_no) || [];

          if (availableCleanRooms.length < maxRooms) {
            errorOccurred = true;
            showErrorToast(
              `Not enough clean rooms available for "${roomCategory.room_name}" . Required: ${maxRooms}, Available: ${availableCleanRooms.length}`
            );
            return;
          }

          autoAssignedRooms[roomCategory.id] = availableCleanRooms.slice(
            0,
            maxRooms
          );
        });

        if (errorOccurred) {
          return {}; // Reset state and exit
        }

        return autoAssignedRooms;
      });
    } else {
      setSelectedRooms({});
    }
  };

  //  console.log( selectedData ,"selectedRoooms")

  return (
    <div>
      <div className="bg-backdrop">
        <div
          style={{
            width: "500px",
            marginBottom: "20px",
            // height: "500px",
            // overflowY: "scroll",
          }}
          className="room-plan-add-model-container  overflow_x_scroll_y_hidden"
        >
          <GoBackButtonCustom
            buttonNextText={`Assign Rooms for Booking ID # : ${uniqueBookingId ? uniqueBookingId : mainData?.unique_booking_id}`}
            onClick={() => [setShowAssignRoomPopup(false), setSelectedRoom("")]}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              flexDirection: "column",
              marginTop: "10px",
            }}
          >
            {(selectedData && selectedData.length) > 0 && (
              <div className="flex flex_gap_10 align_items_center mb-10">
                <span className="global_label">Auto Assign</span>
                <ToggleSwitch
                  size="medium"
                  isOn={checkMaxRooms ? true : false}
                  handleToggle={handleToggle}
                />
              </div>
            )}
            {selectedData && selectedData.length > 0 ? (
              selectedData.map((data, index) => (
                <div
                  key={index}
                  className={`room-box ${
                    selectedRoom === data.name ? "selected" : ""
                  }`}
                >
                  <div className="availableRoomsSelectBoxContainer">
                    <div
                      className="availableRoomsSelectBox"
                      onClick={() => handleRoomClick(data.name, data.maxRooms)}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <span>{data.name}</span>
                        <span>Rooms:({data.maxRooms})</span>
                        <span
                          style={{
                            height: "20px",
                            width: "20px",
                          }}
                        >
                          <svg
                            viewBox="0 0 20 20"
                            className="Polaris-Icon__Svg"
                            focusable="false"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.72 8.47a.75.75 0 0 1 1.06 0l3.47 3.47 3.47-3.47a.75.75 0 1 1 1.06 1.06l-4 4a.75.75 0 0 1-1.06 0l-4-4a.75.75 0 0 1 0-1.06Z"
                            ></path>
                          </svg>
                        </span>
                      </div>
                    </div>
                    {selectedRoom === data.name && (
                      <div className="checkbox-container">
                        {assignRoomsData
                          .find((room) => room.room_name === data.name)
                          .sorted_rooms.map((roomNo) => (
                            <div className="flex flex-gap-10" key={roomNo.id}>
                              <input
                                style={{ marginRight: "5px" }}
                                type="checkbox"
                                checked={data.selectedRoooms.includes(
                                  roomNo.room_no
                                )}
                                id={`room-${roomNo.room_no}`}
                                value={roomNo.room_no}
                                disabled={
                                  roomNo.status === "occupied" ||
                                  roomNo.status === "dirty" ||
                                  roomNo.status === "out_of_order"
                                }
                                onChange={() =>
                                  handleCheckboxChange(
                                    roomNo.room_id,
                                    data.name,
                                    roomNo.id,
                                    roomNo.room_no
                                  )
                                }
                              />
                              <label htmlFor={`room-${roomNo.room_no}`}>
                                {`Room ${
                                  roomNo.room_no
                                } (${roomNo.status.replace(/_/g, " ")})`}
                              </label>
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <>
                <div>No Rooms </div>
              </>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {assignButtonLoader ? (
              <BgThemeButtonDisabled
                // onClick={(e) =>
                //   assignRoomsHandle(e, clicked mainData?.id)
                // }
                children={"Assign"}
              />
            ) : (
              <BgThemeButton
                onClick={(e) => assignRoomsHandle(e, mainData?.id)}
                loader={buttonLoader}
                children={"Assign"}
              />
            )}

            <BgOutlineThemeButton
              onClick={() => [
                setShowAssignRoomPopup(false),
                setSelectedRoom(""),
              ]}
              children={"Discard"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignRoomPopupNew;
